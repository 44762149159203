import { LOADER_ACTION, WEB_3_AUTH } from "./constants";

export const web3authAction = (data) => {

  return {
    type: WEB_3_AUTH,
    payload: data,
  };
};

export const loaderAction = (data) => {
  return {
    type: LOADER_ACTION,
    payload: data,
  };
};
