import CallIcon from "@mui/icons-material/Call";
import { Autocomplete, Button, TextField } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
// import { API } from "../../utils/config";
import {
    adminLoginAction,
    downloadDataAction,
    getListAssistants,
    getListDocument,
    getListNumber,
    makeOutboundCallAction,
    setErrorMessage,
} from "../Redux/actions";
import ErrorMessage from "../component";

const CallVapi = (props) => {
    const min = 0;
    const max = 5;
    const step = 0.1;

    const styles = {
        input: {
            border: "1px solid #f44336", // Red border for error
            borderRadius: "4px",
            padding: "8px",
            width: "100%",
            boxSizing: "border-box",
        },
    };

    const [isChecked, setIsChecked] = useState(false);

    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
    };
    const label = { inputProps: { "aria-label": "Checkbox demo" } };

    function isNumber(value) {
        return typeof value === "number";
    }
    function varifyEmail(email) {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailPattern.test(email);
    }

    useEffect(() => {
        props.getListAssistants();
        props.getListNumber();
        props.getListDocument();
    }, []);

    const navigate = useNavigate();
    const [jsonData, setJsonData] = useState();
    const [formData, setFormData] = useState({
        customerNumber: "",
        phoneNumberId: "",
        assistantId: "",
    });

    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        // Clear error for the field being edited
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: "",
        }));

        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const newErrors = {};

        if (formData.customerNumber.trim() === "") {
            newErrors.customerNumber = "Customer Number is required";
        }
        if (formData.phoneNumberId.trim() === "") {
            newErrors.phoneNumberId = "Phone Number ID is required";
        }
        if (formData.assistantId.trim() === "") {
            newErrors.assistantId = "Assistant ID is required"
        }



        setErrors(newErrors);

        if (Object.keys(newErrors).length === 0) {
            props.makeOutboundCallAction({ ...formData, isPhoneCall: isChecked });
        }
    };

    return props.assistantIDs && props.numberIDs && props.documentIDs ? (
        <>
            <div className="call_component">
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid
                            item
                            xs={12}
                            sx={{ display: "flex", justifyContent: "space-between" }}
                        >
                            <Typography textAlign={"center"} variant="h4">
                                Outbound - PhoneCall
                            </Typography>

                            {/* <Typography
                                onClick={async () => {
                                    props.setErrorMessage([]);
                                    const response = await axios.get(`${API}download_data`);
                                    const jsonData = response.data;
                                    setJsonData(jsonData);
                                    const jsonBlob = new Blob([JSON.stringify(jsonData, null, 2)], {
                                        type: "application/json",
                                    });
                                    saveAs(jsonBlob, "data.json");
                                    // props.downloadDataAction({})
                                }}
                                component={Button}
                            >
                                download data
                            </Typography> */}
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                id="customerNumber"
                                name="customerNumber"
                                label="Customer Number"
                                value={formData.customerNumber}
                                onChange={handleChange}
                                error={Boolean(errors.customerNumber)}
                                helperText={errors.customerNumber}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            {/* <TextField
                                        fullWidth
                                        id="phoneNumberId"
                                        name="phoneNumberId"
                                        label="Phone Number ID"
                                        value={formData.phoneNumberId}
                                        onChange={handleChange}
                                        error={Boolean(errors.phoneNumberId)}
                                        helperText={errors.phoneNumberId}
                                    /> */}

                            <Autocomplete
                                fullWidth
                                // sx={{ overflow: "hidden" }}
                                // className={classes.autocomplete}
                                multiple={false}
                                id="single-select"
                                options={props.numberIDs}
                                onChange={(e, data) => {
                                    if (data) {
                                        setErrors((prevErrors) => ({
                                            ...prevErrors,
                                            phoneNumberId: "",
                                        }));
                                        setFormData((pre) => ({ ...pre, phoneNumberId: data.id }));
                                    } else {
                                        setFormData((pre) => ({ ...pre, phoneNumberId: null }));
                                    }
                                }}
                                getOptionLabel={(option) => option.number + " - " + option.id}
                                disableClearable
                                ChipProps={{ size: "small" }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        // className={classes.autocompletetext}
                                        label="PhoneNumber ID"
                                        placeholder="PhoneNumber ID"
                                        InputProps={{ ...params.InputProps, readOnly: true }}
                                        name="phoneNumberId"
                                        error={Boolean(errors.phoneNumberId)}
                                        helperText={errors.phoneNumberId}
                                    />
                                )}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Autocomplete
                                fullWidth
                                multiple={false}
                                id="single-select"
                                name="assistantId"
                                options={props.assistantIDs}
                                onChange={(e, data) => {
                                    if (data) {
                                        setErrors((prevErrors) => ({
                                            ...prevErrors,
                                            assistantId: "",
                                        }));
                                        setFormData((pre) => ({ ...pre, assistantId: data.id }));
                                    } else {
                                        setFormData((pre) => ({ ...pre, assistantId: null }));
                                    }
                                }}
                                getOptionLabel={(option) => option.name + " - " + option.id}
                                disableClearable
                                ChipProps={{ size: "small" }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        // className={classes.autocompletetext}
                                        label="Assistant ID"
                                        placeholder="Assistant ID"
                                        InputProps={{ ...params.InputProps, readOnly: true }}
                                        name="assistantId"
                                        error={Boolean(errors.assistantId)}
                                        helperText={errors.assistantId}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <div style={{ color: "black" }}>
                                <Checkbox
                                    {...label}
                                    checked={isChecked}
                                    onChange={handleCheckboxChange}
                                />
                                Book Appointment ?
                            </div>
                        </Grid>
                        <Grid textAlign={"center"} item xs={12}>
                            <Button
                                size="large"
                                type="submit"
                                variant="contained"
                                color="primary"
                            >
                                Call <CallIcon sx={{ ml: 1 }} />
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </div>
            <ErrorMessage
                message={props.message}
                setErrorMessage={props.setErrorMessage}
            />
        </>
    ) : (
        <div>
            <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        adminLoginAction: (values) => {
            dispatch(adminLoginAction(values));
        },
        makeOutboundCallAction: (values) => {
            dispatch(makeOutboundCallAction(values));
        },
        setErrorMessage: (values) => {
            dispatch(setErrorMessage(values));
        },
        getListAssistants: (values) => {
            dispatch(getListAssistants(values));
        },
        getListNumber: (values) => {
            dispatch(getListNumber(values));
        },
        getListDocument: (values) => {
            dispatch(getListDocument(values));
        },
        downloadDataAction: (values) => {
            dispatch(downloadDataAction(values));
        },
    };
};

const mapStateToProps = (state) => {
    return {
        adminVerify: state.adminReducer.adminVerify,
        message: state.adminReducer.message,
        assistantIDs: state.adminReducer.assistantIDs,
        numberIDs: state.adminReducer.numberIDs,
        documentIDs: state.adminReducer.documentIDs,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CallVapi);
