import { useSendMessage } from "@xmtp/react-sdk";

import AttachFileIcon from "@mui/icons-material/AttachFile";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import SendIcon from "@mui/icons-material/Send";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import Paper from "@mui/material/Paper";
import {
  AttachmentCodec,
  ContentTypeRemoteAttachment,
  RemoteAttachmentCodec,
} from "@xmtp/content-type-remote-attachment";
import React, { useState } from "react";

export default function CustomizedInputBase(props) {
  const [question, setQuestion] = useState("");
  const [attach, setAttach] = useState("");
  const [attachfile, setAttachfile] = useState("");
  const [attachPreview, setAttachPreview] = useState("");

  const { sendMessage } = useSendMessage();

  const handleSendMessage = async (newMessage) => {
    try {
      if (!newMessage.trim() && !attach) {
        alert("empty message");
        return;
      }
      if (
        props.selectConvertion &&
        props.selectConvertion.peerAddress &&
        attach
      ) {
        uploadAttachment(attach);
      }
      if (
        props.selectConvertion &&
        props.selectConvertion.peerAddress &&
        newMessage
      ) {
        await sendMessage(props.selectConvertion, newMessage);
      }

      setQuestion("");
      setAttachPreview("");
    } catch (err) {
      console.log("err", err);
    }
  };

  async function uploadAttachment(attachment) {
    const encryptedEncoded = await RemoteAttachmentCodec.encodeEncrypted(
      attachment,
      new AttachmentCodec()
    );

    const formData = new FormData();
    formData.append("file", attachfile);
    const metadata = JSON.stringify({
      name: attachfile.name,
    });
    formData.append("pinataMetadata", metadata);
    const options = JSON.stringify({
      cidVersion: 0,
    });
    formData.append("pinataOptions", options);

    const res = await fetch("https://api.pinata.cloud/pinning/pinFileToIPFS", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_VITE_PINATA_JWT}`,
      },
      body: formData,
    });

    const resData = await res.json();

    var cid = resData.IpfsHash;
    var url = `${process.env.REACT_APP_PINATA_GATE_WAY}/${cid}`;

    const remoteAttachment = {
      url: url,
      contentDigest: encryptedEncoded.digest,
      salt: encryptedEncoded.salt,
      nonce: encryptedEncoded.nonce,
      secret: encryptedEncoded.secret,
      scheme: "https://",
      filename: attachment.filename,
      contentLength: attachment.data.byteLength,
    };
    void sendMessage(
      props.selectConvertion,
      remoteAttachment,
      ContentTypeRemoteAttachment
    );

    setAttach("");
    setAttachfile("");
  }

  async function Attachment(files) {
    const file = files[0];
    setAttachfile(file);
    const fileReader = new FileReader();
    fileReader.addEventListener("load", async () => {
      const data = fileReader.result;

      if (!(data instanceof ArrayBuffer)) {
        return;
      }

      const attachment = {
        filename: file.name,
        mimeType: file.type,
        data: new Uint8Array(data),
      };

      setAttachPreview(
        URL.createObjectURL(
          new Blob([Buffer.from(data)], {
            type: attachment.mimeType,
          })
        )
      );
      setAttach(attachment);
    });

    fileReader.readAsArrayBuffer(file);
  }

  return (
    <Paper className="inputPaper">
      {attachPreview &&
        (attach.mimeType == "image/png" || attach.mimeType == "image/jpeg") && (
          <div className="previewContiner">
            <HighlightOffIcon
              className="closeIcon"
              onClick={() => {
                setAttach("");
                setAttachPreview("");
                setAttachfile("");
              }}
            />
            <img src={attachPreview} className="PreviewImage" />
          </div>
        )}
      {attachPreview && (attach.mimeType == "video/mp4" || attach.mimeType == "video/mov") && (
        <div className="previewContiner">
          <HighlightOffIcon
            className="closeIcon"
            onClick={() => {
              setAttach("");
              setAttachPreview("");
              setAttachfile("");
            }}
          />
          <video width="320" height="240" controls className="PreviewImage">
            <source src={attachPreview} type={attach.mimeType} />
          </video>
        </div>
      )}
      {attachPreview && (attach.mimeType == "audio/mpeg" || attach.mimeType == "audio/mp3" || attach.mimeType =="audio/wav" || attach.mimeType =="audio/x-wav") && (
        <div className="previewContiner">
          <HighlightOffIcon
            className="closeIcon"
            onClick={() => {
              setAttach("");
              setAttachPreview("");
              setAttachfile("");
            }}
          />
          <audio controls src={attachPreview}></audio>
        </div>
      )}
      {attachPreview && attach.mimeType == "application/pdf" && (
        <div className="previewContiner">
          <HighlightOffIcon
            className="closeIcon"
            onClick={() => {
              setAttach("");
              setAttachPreview("");
              setAttachfile("");
            }}
          />
          <object
            data={attachPreview}
            type="application/pdf"
            className="PreviewImage"
          ></object>
        </div>
      )}

      <IconButton
        color="primary"
        type="file"
        aria-label="directions"
        className="attachmentButton"
        onClick={() => {
          document.getElementById("chatfile").value = "";
          document.getElementById("chatfile").click();
        }}
      >
        <AttachFileIcon color={"#2196f3"} />
        <input
          type="file"
          id="chatfile"
          accept="image/png, image/jpeg, video/mp4, audio/mp3, application/pdf,audio/mpeg, audio/wav, audio/x-wav, video/mov"
          style={{ display: "none" }}
          onChange={(e) => {
            var file = e.target.files;
            if (file && file.length > 0) {
              Attachment(file);
            }
          }}
        ></input>
      </IconButton>
      <InputBase
        className="inputBase"
        value={question}
        onKeyDown={(ev) => {
          if (ev.key === "Enter") {
            // Do code here
            handleSendMessage(question);
            ev.preventDefault();
          }
        }}
        onChange={(e) => {
          setQuestion(e.target.value);
        }}
        // placeholder="Type a message or type '/' to select a prompt... "
      />

      <Divider
        style={{
          height: 28,
          margin: 4,
        }}
        orientation="vertical"
      />
      <IconButton
        color="primary"
        style={{ padding: 10 }}
        aria-label="directions"
        onClick={() => {
          handleSendMessage(question);
        }}
      >
        <SendIcon className="SendIcon" />
      </IconButton>
    </Paper>
  );
}
