import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import { isAdminLoginAction } from "./Container/Vapi/Redux/actions";



const PublicRoute = (props) => {

    const navigate = useNavigate();

    useEffect(() => {
        props.isAdminLoginAction({ navigate: navigate })
    }, [])


    return (
        <>
            {props.loader ? (
                <div>
                    <Backdrop
                        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={true}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </div>
            ) : (
                <div>
                    <Outlet />
                </div>
            )}

        </>
    );
};



const mapDispatchToProps = (dispatch) => {
    return {
        isAdminLoginAction: (values) => {
            dispatch(isAdminLoginAction(values))
        }
    };
};

const mapStateToProps = (state) => {
    return {
        loader: state.adminReducer.loader,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PublicRoute);