import axios from "axios";
import { API } from "../../../utils/config";

export const adminLoginApi = async (data) => {
    try {
        const result = await axios.post(`${API}login`, data);

        return result;
    } catch (e) {
        console.log(e);
    }
};

export const makeOutboundCallApi = async (data) => {
    try {
        const result = await axios.post(`${API}outbound_call`, data);
        console.log(result, "::::::");
        return result;
    } catch (e) {
        console.log(e);
        return e.response;
    }
};

export const getListAssistantsApi = async (data) => {
    try {
        const result = await axios.get(`${API}list_assistants`, data);
        return result;
    } catch (e) {
        console.log(e);
    }
};

export const getListDocumentApi = async (data) => {
    try {
        const result = await axios.get(`${API}getAllDocument`, data);
        return result;
    } catch (e) {
        console.log(e);
    }
};

export const getListNumbersApi = async (data) => {
    try {
        const result = await axios.get(`${API}list_numbers`, data);
        return result;
    } catch (e) {
        console.log(e);
    }
};

export const addProjectApi = async (data) => {
    try {
        const result = await axios.post(`${API}add_project`, data);
        return result;
    } catch (e) {
        console.log(e);
        return e.response;
    }
};

export const makeOutboundCallCSVApi = async (data) => {
    try {
        const result = await axios.post(`${API}outbound_call_csv`, data);
        return result;
    } catch (e) {
        console.log(e);
        return e.response;
    }
};

export const downloadDataApi = async (data) => {
    try {
        const result = await axios.get(`${API}download_data`);
        return result;
    } catch (e) {
        console.log(e);
    }
};

export const verifyAdminApi = async (data) => {
    try {
        const result = await axios.post(`${API}verify_admin`, data);
        return result;
    } catch (e) {
        return e.response;
    }
};

export const getProjectDataApi = async (data) => {
    try {
        const result = await axios.get(`${API}get_data_projects`);
        return result;
    } catch (e) {
        console.log(e);
    }
};

export const archiveOrUndoApi = async (data) => {
    try {
        const result = await axios.post(`${API}archive`, data);
        return result;
    } catch (e) {
        console.log(e);
        return e.response;
    }
};

export const createAssistantApi = async (data) => {
    try {
        const result = await axios.post(`${API}createAssistant`, data);
        return result;
    } catch (e) {
        return e.response;
    }
};

export const uploadDocumentApi = async (data) => {
    try {
        const result = await axios.post(`${API}uploadDocument`, data);
        return result;
    } catch (e) {
        console.log(e, "error while uploading");
        return e.response;
    }
};

export const deleteDocumentApi = async (data) => {
    try {
        const result = await axios.post(`${API}delete_document`, data);
        return result;
    } catch (e) {
        console.log(e);
    }
};

export const createAssistant = async (body) => {
    try {
        const result = await axios.post(
            `${API}createAssistant`,
            body
        );
        return result;
    } catch (e) {
        console.log(e);
    }
};
