import moment from "moment";
import { useTranslation } from "react-i18next";

export const DateDivider = ({ date }) => {
  const { t } = useTranslation();
  return (
    <div style={{textAlign:"center",borderBottom:"1px solid",lineHeight:"0.1em",margin:"10px 0 20px"}}>
      <span style={{background:"#fff",padding:"0 10px"}}>
        {moment(date).format('ll')}
      </span>
    </div>
  );
};