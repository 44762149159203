import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Button from "@mui/material/Button";
import { useStartConversation } from "@xmtp/react-sdk";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomizedInputBase from "./component/ChatInput";
import ChatMessageList from "./component/ChatMessageList";
import "./index.css";

function RightSideContainer(props) {
  const [createNew, setCreateNew] = useState(false);
  const navigate = useNavigate();
  const { startConversation } = useStartConversation();
  const { width } = props;

  const isValidEthereumAddress = (address) => /^0x[a-fA-F0-9]{40}$/.test(address);

  const handleSearchChange = async (e) => {
    props.searchNewChatAction(e.target.value);
    const addressInput = e.target.value;
    if (addressInput && isValidEthereumAddress(addressInput)) {
      processEthereumAddress(addressInput);
    } else {
      setCreateNew(false);
      props.setMessage("Please enter a valid address.");
    }
  };

  const processEthereumAddress = async (address) => {
    if (address === props.client?.address) {
      props.setMessage("Self-messaging isn't supported.");
      setCreateNew(false);
    } else {
      const canMessageStatus = await props.client?.canMessage(address);
      if (canMessageStatus) {
        props.setMessage("Address active on Soarify's network.");
        setCreateNew(true);
      } else {
        props.setMessage("Address not found on Soarify's network.");
        setCreateNew(false);
      }
    }
  };

  async function createNewChat() {
    if (!createNew) return;
    const newConversation = await startConversation(props.search);
    props.setSelectConvertion(newConversation?.conversation);
    props.searchNewChatAction("");
    props.setMessage("");
    navigate(`/chat?id=${newConversation?.conversation.peerAddress}`);
  }

  return (
    <div className="ChatRightContainer">
      {props.location.pathname === "/chat" && <div />}
      {(props.location.pathname === "/newConversion" ||
        (props.location.pathname === "/chat" &&
          props.location.search !== "")) && (
          <>
            {(props.selectConvertion?.peerAddress ||
              props.location.pathname === "/newConversion" ||
              props.location.pathname === "/chat") && (
                <div className="addressHeader">
                  <div className="inputField" style={{ width: "60%" }}>
                    {width < 1000 && (props.location.pathname == "/newConversion" ||
                      (props.location.pathname == "/chat" &&
                        props.location.search != "")) && (
                        <ArrowBackIcon
                          className="ArrowBack"
                          onClick={() => navigate("/chat")}
                        />
                      )}
                    <input
                      style={{
                        background:
                          props.location.pathname == "/newConversion"
                            ? "#fff"
                            : "transparent",
                        width: "80%",
                        padding: "10px",
                        marginLeft: "10px",
                        marginRight: "10px",
                        color: "#000",
                      }}
                      value={props.location.pathname == "/newConversion"
                        ? props.search
                        : props.selectConvertion?.peerAddress
                          ? props.selectConvertion.peerAddress.substring(0, 6) +
                          "..." +
                          props.selectConvertion.peerAddress.substring(
                            props.selectConvertion.peerAddress.length - 4
                          )
                          : "\u00A0"}
                      disabled={props.location.pathname !== "/newConversion"}
                      onChange={handleSearchChange}
                      className="InputChatName"
                      placeholder="Enter your address here (e.g., 0x123...)"
                    />
                    {createNew && props.location.pathname === "/newConversion" && (
                      <Button onClick={createNewChat} className="newChatButton">
                        Start Chat
                      </Button>
                    )}
                  </div>
                  {props.message &&
                    ((props.location.pathname === "/newConversion" &&
                      props.search) ||
                      props.location.pathname === "/chat") && (
                      <div className="errorMsg">{props.message}</div>
                    )}
                </div>
              )}
            {props.location.pathname != "/newConversion" && (
              <>
                <div className="chatMessageContainer">
                  {props.selectConvertion &&
                    props.selectConvertion.peerAddress && (
                      <ChatMessageList
                        selectConvertion={props.selectConvertion}
                        client={props.client}
                      />
                    )}
                </div>
                {/* <AcceptOrDeny address={walletAddress} /> */}
                <div className="chatInputField">
                  {props.selectConvertion &&
                    props.selectConvertion.peerAddress && (
                      <CustomizedInputBase
                        selectConvertion={props.selectConvertion}
                        client={props.client}
                      />
                    )}
                </div>
              </>
            )}
          </>
        )}
    </div>
  );
}

export default RightSideContainer;
