import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import ShareIcon from '@mui/icons-material/Share';
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import {
  useClient,
  useConsent,
  useConversations,
  useStartConversation,
  useStreamConversations,
} from "@xmtp/react-sdk";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useVapi } from "../../features/Assistant/useVapi";
import { web3authAction } from "../Login/Redux/actions";
import LeftSideContainer from "./LeftSideContainer";
import {
  searchNewChatAction,
  setConversionAction,
  setMessageId,
  shareComponentAction,
} from "./Redux/actions";
import RightSideContainer from "./RightSideContainer";
import ShareLink from "./component/ShareLink";

function ChatGroup(props) {
  const [message, setMessage] = useState("");
  const [storedProfileId, setStoredProfileId] = useState(null);

  useEffect(() => {
    const storedId = localStorage.getItem("messageID");
    if (storedId) {
      setStoredProfileId(storedId);
    }
  }, []);





  const { isAllowed, isDenied, consentState } = useConsent();
  const [pendingResponse, setPendingResponse] = useState(false);

  const { client } = useClient();
  const location = useLocation();
  const navigate = useNavigate();
  const { toggleCall, messages, callStatus, audioLevel } = useVapi();
  const { pathname, search } = location;

  const { conversations, isLoaded } = useConversations();
  const [selectConvertion, setSelectConvertion] = useState(null);
  const [width, setWidth] = useState(window.innerWidth);
  useStreamConversations();
  const { startConversation } = useStartConversation();

  useEffect(() => {
    if (conversations.length > 0) {
      props.setConversionAction(conversations);
    }
  }, [conversations, location.search]);

  const isValidEthereumAddress = (address) => {
    return /^0x[a-fA-F0-9]{40}$/.test(address);
  };


  useEffect(() => {
    const storedId = localStorage.getItem("messageID");
    if (storedProfileId && storedId) {
      localStorage.removeItem('messageID')
      navigate(`/chat?id=${storedProfileId}`)
    }
  })

  useEffect(() => {
    const fetchData = async () => {

      if (location.search && conversations.length > 0) {
        const converse = conversations.find(
          (e) => e.peerAddress == location.search.split("=")[1]
        );
        if (!converse) {
          const addressInput = location.search.split("=")[1];
          // props.searchNewChatAction(addressInput);
          if (addressInput && isValidEthereumAddress(addressInput)) {
            if (addressInput === client?.address) {
              // props.searchNewChatAction("No self messaging allowed");
              setMessage("No self messaging allowed");

            } else {
              const canMessageStatus = await client?.canMessage(addressInput);
              if (canMessageStatus) {
                const newConversation = await startConversation(addressInput);
                setSelectConvertion(newConversation?.conversation);
                setMessage('')
                // props.searchNewChatAction("");
              } else {
                setMessage("Invalid Ethereum address");

              }
            }
          } else {
            setMessage("Invalid Ethereum address");

          }
        } else {
          setSelectConvertion(converse);
          setMessage('')
        }
      }
      else {
        const addressInput = location.search.split("=")[1];
        // props.searchNewChatAction(addressInput);
        if (addressInput && isValidEthereumAddress(addressInput)) {
          if (addressInput === client?.address) {
            // props.searchNewChatAction("No self messaging allowed");
            setMessage("No self messaging allowed");

          } else {
            const canMessageStatus = await client?.canMessage(addressInput);
            if (canMessageStatus) {
              const newConversation = await startConversation(addressInput);
              setSelectConvertion(newConversation?.conversation);
              setMessage('')
              // props.searchNewChatAction("");
            } else {
              setMessage("Invalid Ethereum address");

            }
          }
        } else {
          setMessage("Invalid Ethereum address");

        }
      }
    };
    fetchData();
  }, [conversations.length, location.search, client]);



  // useEffect(() => {
  //   const getRequestConversations = async () => {
  //     debugger;
  //     let isRequestedConversations = await consentState(
  //       selectConvertion?.peerAddress
  //     );
  //     if (isRequestedConversations === "unknown") {
  //       setPendingResponse(true);
  //     }
  //   };

  //   getRequestConversations();
  // }, [consentState, isAllowed, isDenied, selectConvertion]);

  function changeWidth() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", changeWidth);

    return () => {
      window.removeEventListener("resize", changeWidth);
    };
  }, []);

  return (
    <div className="chatMainContainer">
      {/* {isLoaded && props.allConversion.length > 0 && (
     
      )} */}

      <ShareLink
        shareComponentAction={props.shareComponentAction}
        shareModal={props.shareModal}
        client={client}
      />

      {isLoaded || props.allConversion.length > 0 ? (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div className="headerContainer">
            <div className="headerFunction" style={{ width: "240px" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={() => {
                  navigate("/newConversion");
                }}
              >
                {" "}
                <ChatBubbleOutlineIcon
                  sx={{ mr: 2, height: "30px", width: "30px" }}
                />
                New{" "}
              </div>

              <div
                className="share"
                onClick={() => {
                  props.shareComponentAction(true)
                }}
              >
                {/* <img
                  style={{
                    backgroundColor: "white",
                    filter: "brightness(100%)",
                    height: "33px",
                    width: "32px",
                    // border: "1px solid black",
                  }}
                  src={shareIcon}
                ></img> */}
                <ShareIcon sx={{ mr: 2, height: "30px", width: "30px" }} />
                Send Invite
              </div>

              {/* <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={() => {
                  // navigate("/newConversion");
                }}
              >
                {" "}
                <VapiButton
                  audioLevel={audioLevel}
                  callStatus={callStatus}
                  toggleCall={toggleCall}
                />
                Ask AI
              </div> */}

              {/* <div>
                <KeyboardVoiceIcon sx={{ mr: 1 }} />
                Ask AI
              </div> */}
            </div>

            <div className="headerLogo">
              Seriously
              <span
                style={{ display: "inline-block" }}
                className="headerLogoSpan"
              >
                360
              </span>
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div
              className="chatLeftContainer"
              style={{
                width:
                  width < 1000
                    ? location.pathname == "/newConversion" ||
                      (location.pathname == "/chat" && location.search != "")
                      ? "0%"
                      : "100%"
                    : "25%",
              }}
            >
              <LeftSideContainer
                setMessage={setMessage}
                client={client}
                width={width}
                web3auth={props.web3auth}
                web3authAction={props.web3authAction}
                conversation={props.allConversion}
                setSelectConvertion={setSelectConvertion}
                searchNewChatAction={props.searchNewChatAction}
                location={location}
                shareComponentAction={props.shareComponentAction}
                shareModal={props.shareModal}
              />
            </div>
            <div
              className="chatrightContainer"
              style={{
                width:
                  width < 1000
                    ? location.pathname == "/newConversion" ||
                      (location.pathname == "/chat" &&
                        location.search != "" &&
                        selectConvertion)
                      ? "100%"
                      : "0%"
                    : "75%",
              }}
            >
              <RightSideContainer
                message={message}
                setMessage={setMessage}
                client={client}
                width={width}
                selectConvertion={selectConvertion}
                setSelectConvertion={setSelectConvertion}
                search={props.search}
                searchNewChatAction={props.searchNewChatAction}
                location={location}
              />
            </div>
          </div>
        </div>
      ) : (
        <div>
          <Backdrop
            sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    web3authAction: (values) => {
      dispatch(web3authAction(values));
    },
    searchNewChatAction: (values) => {
      dispatch(searchNewChatAction(values));
    },
    setConversionAction: (values) => {
      dispatch(setConversionAction(values));
    },
    shareComponentAction: (values) => {
      dispatch(shareComponentAction(values));
    },
    setMessageId: (values) => {
      dispatch(setMessageId(values));
    },
  };
};

const mapStateToProps = (state) => {
  return {
    web3auth: state.loginReducer.web3auth,
    search: state.chatReducer.search,
    allConversion: state.chatReducer.allConversion,
    shareModal: state.chatReducer.shareModal,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatGroup);
