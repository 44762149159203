import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { CHAIN_NAMESPACES, WEB3AUTH_NETWORK } from "@web3auth/base";
import { EthereumPrivateKeyProvider } from "@web3auth/ethereum-provider";
import { Web3Auth } from "@web3auth/modal";
import { Client, useClient } from "@xmtp/react-sdk";
import { ethers } from "ethers";
import React, { memo, useEffect } from "react";
import { connect } from "react-redux";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { compose } from "redux";
import { setMessageId } from "./Container/ChatRoom/Redux/actions";
import { loaderAction, web3authAction } from "./Container/Login/Redux/actions";

function withRouter(Component) {


  function ComponentWithRouterProp(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();

    return <Component {...props} router={{ location, navigate, params }} />;
  }

  return ComponentWithRouterProp;
}

const PrivateRoute = (props) => {

  const { initialize } = useClient();
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname, search } = location;

  let env;

  // if first time render after login

  useEffect(() => {
    const messageId = location.search.split("=")[1];
    if (!props.web3auth && messageId) {
      isValidEthereumAddress(messageId)
      localStorage.setItem("messageID", messageId)
    }
  }, [])

  const isValidEthereumAddress = (address) => {
    return /^0x[a-fA-F0-9]{40}$/.test(address);
  };





  async function Web3AuthConnectorInstance() {
    props.loaderAction(true);

    const name = "My App Name";

    const chainConfig = {
      chainNamespace: CHAIN_NAMESPACES.EIP155,
      chainId: "0x1",
      // rpcTarget: "https://rpc-mumbai.maticvigil.com",
      rpcTarget:
        "https://eth-mainnet.g.alchemy.com/v2/L-0asVjK4MYNiTlMEL8RlsT3nF0KqV5k",
    };

    const privateKeyProvider = new EthereumPrivateKeyProvider({
      config: { chainConfig },
    });
    const web3AuthInstance = new Web3Auth({
      clientId: process.env.REACT_APP_CLIENT_ID,
      chainConfig,
      privateKeyProvider,
      uiConfig: {
        appName: name,
        loginMethodsOrder: ["github", "google", "linkedin"],
        defaultLanguage: "en",
        modalZIndex: "2147483647",
        logoLight: "https://web3auth.io/images/w3a-L-Favicon-1.svg",
        logoDark: "https://web3auth.io/images/w3a-D-Favicon-1.svg",
        uxMode: "redirect",
        mode: "light",
      },
      web3AuthNetwork: WEB3AUTH_NETWORK.SAPPHIRE_DEVNET,
      enableLogging: true,
    });

    props.web3authAction(web3AuthInstance);
    await web3AuthInstance.initModal();
    let connectedStatus = await isConnected(web3AuthInstance);
    if (connectedStatus) {
      LoginConnect(web3AuthInstance);
    } else {
      console.log("ok go to home")
      props.loaderAction(false);
      navigate("/");
    }
  }
  const getAddress = async (signer) => {
    try {
      if (signer && typeof signer.getAddress === "function") {
        return await signer.getAddress();
      }
      if (signer && typeof signer.getAddresses === "function") {
        //viem
        const [address] = await signer.getAddresses();
        return address;
      }
      return null;
    } catch (e) {
      console.log(e);
    }
  };

  const isConnected = async (web3auth) => {
    if (!web3auth) {
      console.log("web3auth not initialized yet");
      return false;
    }
    return web3auth.status === "connected";
  };

  async function LoginConnect(web3AuthInstance) {
    const web3authProvider = await web3AuthInstance.connect();
    const ethersProvider = new ethers.providers.Web3Provider(web3authProvider);
    const signer = ethersProvider.getSigner();

    initXmtpWithKeys(signer);
  }

  useEffect(() => {
    if (!props.web3auth) {
      Web3AuthConnectorInstance();
    }
  }, [props.router.location.pathname]);

  const initXmtpWithKeys = async (signer) => {
    props.loaderAction(false);

    const options = { env: env ? env : getEnv(), persistConversations: false };
    const address = await getAddress(signer);
    if (!address) return;
    let keys = loadKeys(address);
    if (!keys) {
      keys = await Client.getKeys(signer, {
        ...options,
        skipContactPublishing: true,
        persistConversations: false,
      });
      storeKeys(address, keys);
    }
    await initialize({ keys, options, signer });
  };

  const { ...rest } = props;
  return (
    <>
      {props.loader ? (
        <div>
          <Backdrop
            sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      ) : (
        <div>
          {/* <Route
            {...rest}
            element={(props) => (
              <>
                <React.Component {...props} />
              </>
            )}
          /> */}
          <Outlet />
        </div>
      )}
    </>
  );
};

const ENCODING = "binary";

const getEnv = () => {
  // "dev" | "production" | "local"
  return typeof process !== undefined && process.env.REACT_APP_XMTP_ENV
    ? process.env.REACT_APP_XMTP_ENV
    : "production";
};

export const buildLocalStorageKey = (walletAddress) => {
  return walletAddress ? `xmtp:${getEnv()}:keys:${walletAddress}` : "";
};

export const loadKeys = (walletAddress) => {
  const val = localStorage.getItem(buildLocalStorageKey(walletAddress));
  return val ? Buffer.from(val, ENCODING) : null;
};

export const storeKeys = (walletAddress, keys) => {
  localStorage.setItem(
    buildLocalStorageKey(walletAddress),
    Buffer.from(keys).toString(ENCODING)
  );
};

export const wipeKeys = (walletAddress) => {
  localStorage.removeItem(buildLocalStorageKey(walletAddress));
};

const mapDispatchToProps = (dispatch) => {
  return {
    web3authAction: (values) => {
      dispatch(web3authAction(values));
    },
    loaderAction: (values) => {
      dispatch(loaderAction(values));
    },
    setMessageId: (values) => {
      dispatch(setMessageId(values))
    }
  };
};

const mapStateToProps = (state) => {
  return {
    loader: state.loginReducer.loader,
    web3auth: state.loginReducer.web3auth,
  };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(withRouter(PrivateRoute));
