import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import WavingHandIcon from "@mui/icons-material/WavingHand";
import Snackbar from "@mui/material/Snackbar";
import { useStartConversation } from "@xmtp/react-sdk";
import copy from "copy-to-clipboard";
import React from "react";
import "../../index.css";

const ChatGroupList = (props) => {
  const [open, setOpen] = React.useState(false);

  const { startConversation } = useStartConversation();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="groupBox">
      <Snackbar
        open={open}
        autoHideDuration={1000}
        onClose={handleClose}
        message="Copied!"
      />
      {props.conversation.map((e) => (
        <div className="groupIcon">
          <ContentCopyIcon
            className="copyIcon"
            onClick={() => {
              copy(e.peerAddress);
              setOpen(true);
            }}
          />
          <div
            class="groupItem"
            onClick={() => {
              props.setMessage("")
              props.searchNewChatAction("");
              props.setSelectConvertion(e);
              props.navigate(`/chat?id=${e.peerAddress}`);
            }}
          >
            {e.peerAddress.substring(0, 6) +
              "..." +
              e.peerAddress.substring(e.peerAddress.length - 4)}

            <div>{getRelativeTimeLabel(e.updatedAt)}</div>
          </div>
        </div>
      ))}
      <div
        className="founderContainer"
        onClick={async () => {
          try {
            if (
              props.client.address !=
              "0x71eB73d49434D18E4521F4814A7C7836D5Aead82"
            ) {
              const newConversation = await startConversation(
                "0x71eB73d49434D18E4521F4814A7C7836D5Aead82"
              );
              props.setSelectConvertion(newConversation?.conversation);
              props.navigate(
                `/chat?id=${newConversation?.conversation.peerAddress}`
              );
            }
          } catch (err) {
            console.log("err", err);
          }
        }}
      >
        <WavingHandIcon className="wavinghand" />
        <div className="founderText">
          {props.client?.address === "0x71eB73d49434D18E4521F4814A7C7836D5Aead82"
            ? "Hi Charles, Let's go"
            : (
              <>
                <ul>
                  <li>🚀 Click to DM our team</li>
                  <br />
                  <li><strong>Features:</strong></li>
                  <li>🔒 End-to-end encrypted communication.</li>
                  <li>🌐 Decentralized messaging & file sharing.</li>
                  <li>🔏 Data sovereignty assured.</li>
                  <br />
                  <li><strong>Popular Use Cases:</strong></li>
                  <ul>
                    <li>🔑 Share API keys securely.</li>
                    <li>✉️ Email safety talks to counter phishing.</li>
                    <li>🗣️ Discuss sensitive issues privately.</li>
                    <li>💳 Share private financial transactions.</li>
                    <li>🕵️‍♂️ Cybersecurity threat discussions.</li>
                    <li>✨ And more...</li>

                  </ul>
                </ul>
              </>
            )
          }
        </div>
      </div>
    </div>
  );
};

const getRelativeTimeLabel = (dateString) => {
  const diff = new Date() - new Date(dateString);
  const diffSecond = Math.floor(diff / 1000);
  const diffMinutes = Math.floor(diff / 1000 / 60);
  const diffHours = Math.floor(diff / 1000 / 60 / 60);
  const diffDays = Math.floor(diff / 1000 / 60 / 60 / 24);
  const diffWeeks = Math.floor(diff / 1000 / 60 / 60 / 24 / 7);

  if (diffSecond < 60)
    return `${diffSecond} second${diffSecond > 1 ? "s" : ""} ago`;
  if (diffMinutes < 60)
    return `${diffMinutes} minute${diffMinutes > 1 ? "s" : ""} ago`;
  if (diffHours < 24) return `${diffHours} hour${diffHours > 1 ? "s" : ""} ago`;
  if (diffDays < 7) return `${diffDays} day${diffDays > 1 ? "s" : ""} ago`;
  return `${diffWeeks} week${diffWeeks > 1 ? "s" : ""} ago`;
};

export default ChatGroupList;
