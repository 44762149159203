import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import Divider from "@mui/material/Divider";
import Snackbar from "@mui/material/Snackbar";
import { useClient } from "@xmtp/react-sdk";
import copy from "copy-to-clipboard";
import React from "react";
import { useNavigate } from "react-router-dom";
import { wipeKeys } from "../../privateRoute";
import ChatGroupList from "./component/ChatGroupList/index";

import "./index.css";

function LeftSideContainer(props) {
  const navigate = useNavigate();

  const { client, disconnect } = useClient();

  const handleLogout = async () => {
    if (!props.web3auth) {
      console.log("web3auth not initialized yet");
      return;
    }

    await props.web3auth.logout();
    disconnect();
    wipeKeys(client?.address ?? "");
    indexedDB.deleteDatabase("__XMTP__");

    props.web3authAction("");
    navigate("/");
  };

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };



  // Redis integration for blocklist/whitelist functionality
  // Placeholder for Redis client setup
  // const redisClient = setupRedisClient(); // Ensure you setup this function to initialize your Redis client

  /* 
  // Example Redis usage for blocklist check (This is a conceptual placeholder)
  const isBlocked = await redisClient.sismember(`user:blocklist:${client.address}`, "targetAddress");
  if (isBlocked) {
    // Logic to handle blocked addresses
  }

  // Example Redis usage for adding to the whitelist (This is a conceptual placeholder)
  const addToWhitelist = (address) => {
    redisClient.sadd(`user:whitelist:${client.address}`, address);
  };
  */

  return (
    <div className="myAddressContainer">
      <Snackbar
        open={open}
        autoHideDuration={1000}
        onClose={handleClose}
        message="Copied!"
      />
      <div className="sideMenuButton">
        {props.client ? (
          <div className="myAddress">
            <ContentCopyIcon
              className="copyIcon"
              onClick={() => {
                copy(props.client.address);
                setOpen(true);
              }}
            />
            <span className="myaddressText">
              {props.client.address.substring(0, 6) +
                "..." +
                props.client.address.substring(props.client.address.length - 4)}
            </span>
          </div>
        ) : (
          <span>-</span>
        )}
        <div>
          {/* <span
            className="share"
            onClick={() => {
              props.shareComponentAction(true)
            }}
          >
            <img
              style={{
                height: "33px",
                width: "32px",
                // border: "1px solid black",
              }}
              src={shareIcon}
            ></img>
          </span> */}

          <ExitToAppIcon
            className="exitIcon"
            onClick={() => {
              handleLogout();
            }}
          />
        </div>
      </div>
      <Divider className="GroupDivider" />
      <ChatGroupList
        setMessage={props.setMessage}
        conversation={props.conversation}
        client={props.client}
        setSelectConvertion={props.setSelectConvertion}
        searchNewChatAction={props.searchNewChatAction}
        navigate={navigate}
      />
      {/* Additional UI elements for managing blocklist/whitelist could be added here */}
    </div>
  );
}

export default LeftSideContainer;
