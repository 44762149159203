import CloseIcon from "@mui/icons-material/Close";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Modal from "@mui/material/Modal";
import copy from "copy-to-clipboard";
import { QRCode } from "react-qrcode-logo";
import "./ShareModal.css";

const ShareLink = (props) => {

    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 450,
        height: 600,
        background:
            "linear-gradient(to bottom, #2D124C 0%, #52246F 50%, #7C4795 100%)",
        borderRadius: "10px",
        // border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        display: "flex",
    };
    const handleClose = () => props.shareComponentAction(false);


    let origin =
        window.location.origin === "http://localhost:3000"
            ? "https://soarify.xyz"
            : window.location.origin;
    let path = "/chat";
    let id = `?id=${props.client?.address}`;

    return (

        props.client ? (<Modal
            open={props.shareModal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box className="share_modal" sx={style}>
                <span
                    className="close_modal"
                    onClick={() => {
                        props.shareComponentAction(false);
                    }}
                >
                    <CloseIcon />
                </span>

                <div className="modal_inner_content">
                    <div className="headerLogo">
                        Seriously
                        <span
                            style={{ display: "inline-block" }}
                            className="headerLogoSpan"
                        >
                            360
                        </span>
                    </div>

                    <p>
                        Invite your friends, family, and colleagues to join the Seriously 360 network for end-to-end encrypted 
                        communication, decentralized messaging and file sharing, ensuring your data remains private and 
                        secure from eavesdropping.
                    </p>

                    <div className="QR_box">
                        <QRCode qrStyle="dots" size={200} value={`${origin}${path}${id}`} />
                    </div>

                    <div className="link_url">
                        <span
                            onClick={() => {
                                copy(`${origin}${path}${id}`);
                                // setOpen(true);
                            }}
                        >
                            Copy Your Invitation Link
                        </span>
                        <span>
                            <ContentCopyIcon />
                        </span>
                    </div>
                </div>
            </Box>
        </Modal>) : (<div>
            <Backdrop
                sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={true}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>)

    );
};

export default ShareLink;
