import { Autocomplete, Button, TextField } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";

import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
    addProjectAction,
    getListAssistants,
    getListNumber,
    makeOutboundCallCSVAction,
    setErrorMessage,
} from "../Redux/actions";
import ErrorMessage from "../component";
import "./UploadComponent.css";

const AddProject = (props) => {
    const styles = {
        input: {
            border: "1px solid #f44336", // Red border for error
            borderRadius: "4px",
            padding: "8px",
            width: "100%",
            boxSizing: "border-box",
        },
    };

    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

    const navigate = useNavigate();

    // useEffect(() => {
    //     if (!props.adminVerify) {
    //         navigate("/")
    //     }
    // }, [props.adminVerify])

    useEffect(() => {
        props.getListAssistants();
        props.getListNumber();
    }, []);

    const [isChecked, setIsChecked] = useState(false);

    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
    };

    const [formData, setFormData] = useState({
        assistantId: "",
        customerNumber: "",
        phoneNumberId: "",
        projectName: "",
    });

    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value, files } = e.target;

        // Clear error for the field being edited
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: "",
        }));
        if (name === "customerNumber") {
            setFormData((prevFormData) => ({
                ...prevFormData,
                [name]: files[0],
            }));
        } else {
            setFormData((prevFormData) => ({
                ...prevFormData,
                [name]: value,
            }));
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const newErrors = {};
        if (formData.projectName.trim() === "") {
            newErrors.projectName = "Project Name is required";
        }
        if (formData.assistantId.trim() === "") {
            newErrors.assistantId = "Assistant ID is required";
        }
        if (!formData.customerNumber) {
            newErrors.customerNumber = "CSV File is required";
        }
        if (formData.phoneNumberId.trim() === "") {
            newErrors.phoneNumberId = "Phone Number ID is required";
        }
        setErrors(newErrors);

        if (Object.keys(newErrors).length === 0) {

            props.addProjectAction({ formData: { ...formData, isPhoneCall: isChecked }, navigate: navigate });
        }
    };

    return props.assistantIDs && props.numberIDs ? (
        <>
            <div className="upload">
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid
                            item
                            xs={12}
                            sx={{ display: "flex", justifyContent: "space-between" }}
                        >
                            <Typography textAlign={"center"} variant="h4">
                                Add Project
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                id="projectName"
                                name="projectName"
                                label="Project Name"
                                value={formData.projectName}
                                onChange={handleChange}
                                error={Boolean(errors.projectName)}
                                helperText={errors.projectName}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Autocomplete
                                fullWidth
                                multiple={false}
                                id="single-select"
                                name="assistantId"
                                options={props.assistantIDs}
                                onChange={(e, data) => {
                                    if (data) {
                                        setErrors((prevErrors) => ({
                                            ...prevErrors,
                                            assistantId: "",
                                        }));
                                        setFormData((pre) => ({ ...pre, assistantId: data.id }));
                                    } else {
                                        setFormData((pre) => ({ ...pre, assistantId: null }));
                                    }
                                }}
                                getOptionLabel={(option) => option.name + " - " + option.id}
                                disableClearable
                                ChipProps={{ size: "small" }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        // className={classes.autocompletetext}
                                        label="Assistant ID"
                                        placeholder="Assistant ID"
                                        InputProps={{ ...params.InputProps, readOnly: true }}
                                        name="assistantId"
                                        error={Boolean(errors.assistantId)}
                                        helperText={errors.assistantId}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <input
                                type="file"
                                id="customerNumber"
                                name="customerNumber"
                                onChange={handleChange}
                                style={errors.customerNumber ? styles.input : null}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Autocomplete
                                fullWidth
                                multiple={false}
                                id="single-select"
                                options={props.numberIDs}
                                onChange={(e, data) => {
                                    if (data) {
                                        setErrors((prevErrors) => ({
                                            ...prevErrors,
                                            phoneNumberId: "",
                                        }));
                                        setFormData((pre) => ({ ...pre, phoneNumberId: data.id }));
                                    } else {
                                        setFormData((pre) => ({ ...pre, phoneNumberId: null }));
                                    }
                                }}
                                getOptionLabel={(option) => option.number + " - " + option.id}
                                disableClearable
                                ChipProps={{ size: "small" }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        // className={classes.autocompletetext}
                                        label="PhoneNumber ID"
                                        placeholder="PhoneNumber ID"
                                        InputProps={{ ...params.InputProps, readOnly: true }}
                                        name="phoneNumberId"
                                        error={Boolean(errors.phoneNumberId)}
                                        helperText={errors.phoneNumberId}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <div style={{ color: "black" }}>

                                <Checkbox
                                    {...label}
                                    checked={isChecked}
                                    onChange={handleCheckboxChange}
                                />
                                Book Appointment ?
                            </div>
                        </Grid>
                        <Grid textAlign={"center"} item xs={12}>
                            <Button
                                size="large"
                                type="submit"
                                variant="contained"
                                color="primary"
                            >
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </div>
            <ErrorMessage
                message={props.message}
                setErrorMessage={props.setErrorMessage}
            />
        </>
    ) : (
        <div>
            <Backdrop
                sx={{
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={true}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        setErrorMessage: (values) => {
            dispatch(setErrorMessage(values));
        },
        addProjectAction: (values) => {
            dispatch(addProjectAction(values));
        },
        getListAssistants: (values) => {
            dispatch(getListAssistants(values));
        },
        getListNumber: (values) => {
            dispatch(getListNumber(values));
        },
        makeOutboundCallCSVAction: (values) => {
            dispatch(makeOutboundCallCSVAction(values));
        },
    };
};

const mapStateToProps = (state) => {
    return {
        adminVerify: state.adminReducer.adminVerify,
        message: state.adminReducer.message,
        assistantIDs: state.adminReducer.assistantIDs,
        numberIDs: state.adminReducer.numberIDs,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddProject);
