import { combineReducers } from "redux";
import chatReducer from "../Container/ChatRoom/Redux/reducer";
import loginReducer from "../Container/Login/Redux/reducer";
import adminReducer from "../Container/Vapi/Redux/reducer";

export default combineReducers({
  chatReducer: chatReducer,
  loginReducer: loginReducer,
  adminReducer: adminReducer
});
