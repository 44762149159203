import {
  GET_ALL_CHAT_LIST,
  SEARCH_NEW_CHAT,
  SET_CONVERSION,
  SET_MESSAGE_ID,
  SHARE_MODAL,
} from "./constants";

export const getAllChatListAction = (value) => {
  return {
    type: GET_ALL_CHAT_LIST,
    payload: value,
  };
};

export const searchNewChatAction = (value) => {
  return {
    type: SEARCH_NEW_CHAT,
    payload: value,
  };
};
export const setConversionAction = (value) => {
  return {
    type: SET_CONVERSION,
    payload: value,
  };
};

export const shareComponentAction = (value) => {
  return {
    type: SHARE_MODAL,
    payload: value
  }
}


export const setMessageId = (value) => {

  return {
    type: SET_MESSAGE_ID,
    payload: value
  }
}