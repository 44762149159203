import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setErrorMessage } from "../Redux/actions";

const ErrorMessage = (props) => {
  const state = useSelector((state) => state.adminReducer.message);
  console.log(state);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (state.message) {
      setOpen(true);
    }
  }, [state.message]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    dispatch(setErrorMessage({ message: undefined, status: undefined }));
  };

  return (
    <>
      {state.message && (
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={state.status}
            variant="filled"
            sx={{ width: "100%" }}
          >
            {state.message}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};

export default ErrorMessage;
