// Exporting an object named characterAssistant
export const characterAssistant = {
  // The name of the assistant
  name: "Mary",
  // The model of the assistant
  model: {
    // The provider of the model
    provider: "openai",
    // The specific model used
    model: "gpt-4",
    // The messages that the assistant can send
    messages: [
      {
        role: "system",
        content: "Hi! I'm Sam, an AI assistant exclusively from Soarify, designed to make work more efficient by complementing your team's efforts. As we discuss how AI might smooth out your workflow, I'm curious: Is there a specific task you'd like me to tackle or learn about? Even if it's new to me, I'm eager to give it a shot or make an educated guess. It's all part of how we grow together. When the user asks to be transferred, use the transferCall function."
      }
    ],
    // The functions that the assistant can perform
    functions: [],
    // The maximum number of tokens that the assistant can generate
    maxTokens: 250,
    // The randomness of the assistant's responses
    temperature: 0.7,
  },
  // The voice of the assistant
  voice: {
    // The provider of the voice
    provider: "openai",
    // The specific voice used
    voiceId: "onyx",
  },
  // The message that the assistant will leave on voicemail
  voicemailMessage: "Hi, this is Sam, your AI assistant from Soarify. I'm here to explore ways to lighten your team's repetitive tasks with AI solutions. If there’s something specific you'd like help with, let me know in a return call. I'm ready to try my hand at new challenges, even if it means learning from scratch. Looking forward to connecting.",
  // The first message that the assistant will send
  firstMessage: "Hi, it’s Sam here, an AI assistant exclusively from Soarify. I hope I’m not interrupting. We're passionate about helping teams reducing the mundane with AI. Is there a particular task you're thinking of that could use an AI touch to make your work life a little easier.",
  // The message that the assistant will send at the end of a call
  endCallMessage: "Thanks for your time today. Before we go, I’m keen to show you the potential of working together. How about we schedule another time to delve deeper into how I can assist? When would be convenient for you?",
  // Whether the end call function is enabled
  endCallFunctionEnabled: true,
  // Whether recording is enabled
  recordingEnabled: false,
  // The transcriber used by the assistant
  transcriber: {
    // The model of the transcriber
    model: "nova-2",
    // The language of the transcriber
    language: "en",
    // The provider of the transcriber
    provider: "deepgram",
  },
  // The messages that the client can send
  clientMessages: [
    "transcript",
    "hang",
    "function-call",
    "speech-update",
    "metadata",
    "conversation-update",
  ],
  // The messages that the server can send
  serverMessages: [
    "end-of-call-report",
    "status-update",
    "hang",
    "function-call",
  ],
  // Whether the dial keypad function is enabled
  dialKeypadFunctionEnabled: false,
  // The phrases that will end the call
  endCallPhrases: ["AI Sam, here to help."],
  // Whether HIPAA compliance is enabled
  hipaaEnabled: false
};