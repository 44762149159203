import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import ChatGroup from "./Container/ChatRoom/index";
import Login from "./Container/Login";
import AddProject from "./Container/Vapi/addProject";
import ArchiveProject from "./Container/Vapi/archiveProject";
import CallVapi from "./Container/Vapi/call";
import CreateAssistant from "./Container/Vapi/createAssistant";
import AdminLogin from "./Container/Vapi/index";
import Layout from "./Container/Vapi/layout";
import listDocument from "./Container/Vapi/listDocument";
import ProjectData from "./Container/Vapi/projectData";
import uploadDocument from "./Container/Vapi/uploadDocument";
import AdminRoute from "./adminRoute";
import PrivateRoute from "./privateRoute";
import PublicRoute from "./publicRoute";
import ErrorMessage from "./Container/Vapi/component";

function App() {
  const AddProjectComponent = Layout(AddProject);
  const CallVapiComponent = Layout(CallVapi);
  const ProjectDataComponent = Layout(ProjectData);
  const ArchiveProjectComponent = Layout(ArchiveProject);
  const CreateAssistantComponent = Layout(CreateAssistant);
  const UploadDocumentComponent = Layout(uploadDocument);
  const ListDocumentComponent = Layout(listDocument)

  return (
    <div className="App">
      <ErrorMessage />
      <BrowserRouter>
        <Routes>
          <Route element={<PrivateRoute />}>
            <Route path="/" element={<Login />} />
            <Route path="/chat" element={<ChatGroup />} />
            <Route path="/newConversion" element={<ChatGroup />} />
          </Route>

          <Route element={<AdminRoute />}>
            <Route path="/project" element={<AddProjectComponent />} />
            <Route path="/call" element={<CallVapiComponent />} />
            <Route path="/table" element={<ProjectDataComponent />} />
            <Route path="/archive" element={<ArchiveProjectComponent />} />
            <Route path="/createAssistant" element={<CreateAssistantComponent />} />
            <Route path="/upload" element={<UploadDocumentComponent />} />
            <Route path="/listDocument" element={<ListDocumentComponent />} />
          </Route>

          <Route element={<PublicRoute />}>
            <Route path="/admin" element={<AdminLogin />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
