// Importing the character assistant from the assistants directory
import { characterAssistant } from "../../assistants/character";

// Trying to use the Dynamically import the assistant based on the environment variable
// const assistantName = process.env.VAPI_ASSISTANT_KEY;
// vapi.start();

// Then you can use the `assistant` in your code
//vapi.start(assistant);

// Importing the useEffect and useState hooks from React
import { useEffect, useState } from "react";

// Importing the vapi SDK
import { vapi } from "./vapi.sdk";

// Defining the possible statuses of a call
export const CALL_STATUS = {
  INACTIVE : "inactive",
  ACTIVE : "active",
  LOADING : "loading",
}

// Defining the useVapi function
export function useVapi() {
  // Defining the state variables
  const [isSpeechActive, setIsSpeechActive] = useState(false);
  const [callStatus, setCallStatus] = useState(
    CALL_STATUS.INACTIVE
  );
  const [messages, setMessages] = useState([]);
  const [activeTranscript, setActiveTranscript] =
    useState(null);
  const [audioLevel, setAudioLevel] = useState(0);

  // Defining the useEffect hook
  useEffect(() => {
    // Defining the event handlers
    const onSpeechStart = () => setIsSpeechActive(true);
    const onSpeechEnd = () => {
      console.log("Speech has ended");
      setIsSpeechActive(false);
    };
    const onCallStartHandler = () => {
      console.log("Call has started");
      setCallStatus(CALL_STATUS.ACTIVE);
    };
    const onCallEnd = () => {
      console.log("Call has stopped");
      setCallStatus(CALL_STATUS.INACTIVE);
    };
    const onVolumeLevel = (volume) => {
      setAudioLevel(volume);
    };
    const onError = (e) => {
      setCallStatus(CALL_STATUS.INACTIVE);
      console.error(e);
    };

    // Adding the event listeners
    vapi.on("speech-start", onSpeechStart);
    vapi.on("speech-end", onSpeechEnd);
    vapi.on("call-start", onCallStartHandler);
    vapi.on("call-end", onCallEnd);
    vapi.on("volume-level", onVolumeLevel);
    vapi.on("error", onError);

    // Removing the event listeners when the component unmounts
    return () => {
      vapi.off("speech-start", onSpeechStart);
      vapi.off("speech-end", onSpeechEnd);
      vapi.off("call-start", onCallStartHandler);
      vapi.off("call-end", onCallEnd);
      vapi.off("volume-level", onVolumeLevel);
      vapi.off("error", onError);
    };
  }, []);

  // Defining the start function
  const start = async () => {
    setCallStatus(CALL_STATUS.LOADING);
    const response = vapi.start(characterAssistant);

    response.then((res) => {
      console.log("call", res);
    });
  };

  // Defining the stop function
  const stop = () => {
    setCallStatus(CALL_STATUS.LOADING);
    vapi.stop();
  };

  // Defining the toggleCall function
  const toggleCall = () => {
    if (callStatus == CALL_STATUS.ACTIVE) {
      stop();
    } else {
      start();
    }
  };

  // Returning the state variables and functions
  return {
    isSpeechActive,
    callStatus,
    audioLevel,
    messages,
    start,
    stop,
    toggleCall,
  };
}
