import LoadingButton from "@mui/lab/LoadingButton";
import Grid from "@mui/material/Grid";
import React from "react";
import { useDropzone } from "react-dropzone";
import {
    BsFiletypeCsv,
    BsFiletypeDoc,
    BsFiletypeTxt,
    BsFiletypeXlsx,
    BsFillFileEarmarkPdfFill,
} from "react-icons/bs";
import { LiaUploadSolid } from "react-icons/lia";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
    dropDocumentAction,
    setErrorMessage,
    uploadDocumentPdfAction,
} from "../Redux/actions";
import ErrorMessage from "../component";

const UploadDocument = (props) => {
    const navigate = useNavigate();
    const document = props.dropDocument;

    const fileTypeOFDoc = document?.name.split(".");
    const fileTypeForIcons = document
        ? fileTypeOFDoc[fileTypeOFDoc?.length - 1]
        : "";
    const fileName = document?.name;

    const onDrop = React.useCallback((acceptedFiles) => {
        Object.assign(acceptedFiles[0], {
            preview: URL.createObjectURL(acceptedFiles[0]),
        });
        props.dropDocumentAction(acceptedFiles[0]);
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
    });

    const uploadPDFDocument = () => {
        props.uploadDocumentPdfAction({ document: document, navigate: navigate });
    };

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "100vh",
            }}
        >
            <div
                {...getRootProps()}
                style={{
                    border: "2px solid black",
                    borderRadius: "20px",
                    padding: "3px",
                    marginTop: "20px",
                    width: "",
                    height: "300px",
                    width: "300px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                {document ? (
                    <>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                padding: "10px",
                            }}
                        >
                            <div
                                style={{
                                    fontSize: "50px",
                                }}
                            >
                                {(fileTypeForIcons === "pdf" && <BsFillFileEarmarkPdfFill />) ||
                                    (fileTypeForIcons === "csv" && <BsFiletypeCsv />) ||
                                    (fileTypeForIcons === "xlsx" && <BsFiletypeXlsx />) ||
                                    (fileTypeForIcons === "txt" && <BsFiletypeTxt />) ||
                                    (fileTypeForIcons === "docx" && <BsFiletypeDoc />)}
                            </div>
                            <p
                                style={{
                                    fontSize: "20px",
                                    textAlign: "center",
                                    lineHeight: "30px",
                                }}
                            >
                                {fileName}
                            </p>
                        </div>
                    </>
                ) : (
                    <>
                        {isDragActive ? (
                            <p
                                style={{
                                    fontSize: "20px",
                                    textAlign: "center",
                                }}
                            >
                                Drop the files here...
                            </p>
                        ) : (
                            <p
                                style={{
                                    fontSize: "20px",
                                    textAlign: "center",
                                }}
                            >
                                Drag & Drop Files Here
                            </p>
                        )}
                        <div
                            style={{
                                fontSize: "50px",
                            }}
                        >
                            <LiaUploadSolid />
                        </div>
                    </>
                )}
                <input {...getInputProps()} />
            </div>

            {document && (
                <Grid textAlign={"center"} item xs={12} mt={2}>
                    <LoadingButton
                        loading={props.documentLoader}
                        document="document"
                        size="large"
                        type="submit"
                        variant="contained"
                        color="primary"
                        onClick={uploadPDFDocument}
                    >
                        UPLOAD
                    </LoadingButton>
                </Grid>
            )}
            <ErrorMessage
                message={props.message}
                setErrorMessage={props.setErrorMessage}
            />
        </div>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        // getProjectDataAction: (values) => {
        //     dispatch(getProjectDataAction(values));
        // },
        // makeOutboundCallCSVAction: (values) => {
        //     dispatch(makeOutboundCallCSVAction(values));
        // },
        // setErrorMessage: (values) => {
        //     dispatch(setErrorMessage(values));
        // },
        // archiveProjectAction: (values) => {
        //     dispatch(archiveProjectAction(values))
        // }
        uploadDocumentPdfAction: (values) => {
            dispatch(uploadDocumentPdfAction(values));
        },
        dropDocumentAction: (values) => {
            dispatch(dropDocumentAction(values));
        },
        setErrorMessage: (values) => {
            dispatch(setErrorMessage(values));
        },
    };
};

const mapStateToProps = (state) => {
    return {
        projectData: state.adminReducer.projectData,
        message: state.adminReducer.message,
        dropDocument: state.adminReducer.dropDocument,
        documentLoader: state.adminReducer.documentLoader,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadDocument);
