import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logoutAdminAction, setErrorMessage } from "../Redux/actions";

const drawerWidth = 240;

const Sidebar = (props) => {
    const navigate = useNavigate();

    return (
        <Drawer
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                "& .MuiDrawer-paper": {
                    width: drawerWidth,
                    boxSizing: "border-box",
                },
            }}
            variant="permanent"
            anchor="left"
        >
            <Toolbar />
            <Divider />

            <List>
                {[
                    { tag: "Add Project", link: "/project" },
                    { tag: "Outbound Call", link: "/call" },
                    { tag: "Projects", link: "/table" },
                    { tag: "Archive", link: "/archive" },
                    // { tag: "Upload-Document", link: "/upload" },
                    { tag: "Documents", link: "/listDocument" },
                    { tag: "Create Assistant", link: "/createAssistant" }
                ].map((element, index) => (
                    <ListItem key={element.tag} disablePadding>
                        <ListItemButton
                            onClick={() => {
                                props.setErrorMessage([]);
                                navigate(element.link);
                            }}
                        >
                            <ListItemText primary={element.tag} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>

            <Divider />

            <List sx={{ mt: 30 }}>
                <Divider />
                <ListItem disablePadding>
                    <ListItemButton
                        onClick={() => {
                            props.logoutAdminAction({ navigate: navigate });
                        }}
                    >
                        <ListItemText primary={"Logout"} />
                    </ListItemButton>
                </ListItem>
                <Divider />
            </List>
        </Drawer>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        setErrorMessage: (values) => {
            dispatch(setErrorMessage(values));
        },
        logoutAdminAction: (values) => {
            dispatch(logoutAdminAction(values));
        },
    };
};

const mapStateToProps = (state) => {
    return {
        adminVerify: state.adminReducer.adminVerify,
        message: state.adminReducer.message,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
