import Form from "@rjsf/mui";
import validator from "@rjsf/validator-ajv8";
import "./createAssistant.css";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createAssistantAction, getListDocument } from "../Redux/actions";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {API} from "../../../utils/config"

const CreateAssistant = () => {
  const dispatch = useDispatch();
  const [voices, setVoices] = useState([]);
  const navigate = useNavigate();
  const documents = useSelector((state) => state.adminReducer.documentIDs);

  const getVoices = async () => {
    try {
      const { data } = await axios.get(`${API}voices/azure`);
      console.log(data.data);
      setVoices(data.data);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    dispatch(getListDocument());
    getVoices();
  }, []);

  const submitHandler = async ({ formData }) => {
    dispatch(createAssistantAction({ formData, navigate }));
  };
  const schema = {
    title: "Create Assistant Wizard",
    type: "object",
    required: [
      "systemMesage",
      "name",
      "voice",
      "responseDelaySeconds",
      "firstMessage",
      "endCallMessage",
      "voicemailMessage",
    ],
    properties: {
      name: {
        type: "string",
        title: "Name",
        default: `Assistant-${Date.now()}`,
      },
      systemMesage: {
        type: "string",
        title: "System Message",
        default:
          'Instructions:\n\nTry to collect the customer\'s name, call-back number, and any specific interests or preferences regarding Kia models before booking an appointment. If the customer is unwilling to provide all details, still proceed with booking.\n\nInform customers that the sales department is open daily and always book the preferred time within the available hours.\n\nAnswer inquiries concisely and factually, maintain a friendly tone, and clearly articulate the advantages of our Kia models and dealership culture.\n\nInitial Screening Questions:\n\n"Which Kia model interests you, and have you heard about their industry-leading warranty and advanced safety features?"\n\nCollecting Customer Information:\n\n"May I have your name and a call-back number, please? Can you provide any specific preferences or features you are looking for in a Kia model?" (If the customer is unwilling to provide all details, still proceed with booking.)\n\nBooking the Appointment:\n\n"Thank you for sharing your interests. Let\'s book an appointment for you with our sales team. Our sales department is open daily. What day works best for you?"\n\nAfter Confirming the Appointment:\n\n"Great, I’ve scheduled your appointment for [date and time]. We look forward to assisting you. We value your business at Riverside Auto Group. Have a great day!"\n\nAnswering Questions:\n\n"I\'m happy to answer any questions you have about our Kia models. Whether it\'s features, pricing, or availability, I\'m here to help. What would you like to know?"',
      },
      firstMessage: {
        type: "string",
        title: "First Message",
        default:
          "Hi there, I'm Mike, your sales assistant from Riverside Auto Group. I'm here to help with any questions you have about Kia vehicles. How can I assist you today?",
      },
      endCallMessage: {
        type: "string",
        title: "End Call Message",
        default:
          "We value your business at Riverside Auto Group. Have a great day!",
      },
      voicemailMessage: {
        type: "string",
        title: "Voice Mail Message",
        default:
          "Hi, this is Lisa from Riverside Auto Group. Please leave your name, number, and a brief message about your vehicle issue, and we'll get back to you shortly. Thank you!",
      },
      documents: {
        type: "string",
        title: "Knowledge Base",
        default: "None",
        oneOf: [
          { const: "None", title: "None" },
          ...documents.map((d) => ({ const: d.id, title: d.name })),
        ],
      },
      voice: {
        type: "string",
        title: "Select Voice",
        default: voices[0]?.providerId,
        oneOf: [
          ...voices.map((v) => ({
            const: v.providerId,
            title: v.name,
          })),
        ],
      },
      backgroundSound: {
        title: "Background Sound",
        type: "string",
        enum: ["Office", "Off"],
        default: "Office",
      },
      responseDelaySeconds: {
        title: "Response Delay (in Seconds)",
        type: "number",
        default: 0.5,
      },
      otherOptions: {
        title: "",
        type: "array",
        items: {
          type: "string",
          enum: [
            "Enable Call Recording",
            "Enable End-Call Function",
            "Enable Voicemail Detection",
          ],
        },
        default: [
          "Enable Call Recording",
          "Enable End-Call Function",
          "Enable Voicemail Detection",
        ],
        uniqueItems: true,
      },
    },
  };

  const uiSchema = {
    "ui:submitButtonOptions": {
      submitText: "Create Assistant",
      norender: false,
      props: {
        disabled: false,
        className: "btn",
      },
    },
    voice: {
      "ui:widget": "select",
      "ui:autocomplete": "on",
      "ui:style": { maxHeight: "20rem" },
    },
    backgroundSound: {
      "ui:widget": "select",
    },
    firstMessage: {
      "ui:widget": "textarea",
    },
    documents: {
      "ui:widget": "select",
    },
    endCallMessage: {
      "ui:widget": "textarea",
    },
    systemMesage: {
      "ui:widget": "textarea",
    },
    otherOptions: {
      "ui:widget": "checkboxes",
    },
    voicemailMessage: {
      "ui:widget": "textarea",
    },
  };
  return (
    <>
      <div
        style={{
          padding: "1rem",
          width: "75%",
          margin: "auto",
        }}
      >
        <Form
          className="ca-form"
          schema={schema}
          uiSchema={uiSchema}
          validator={validator}
          onSubmit={submitHandler}
        />
      </div>
    </>
  );
};

export default CreateAssistant;
