export const SET_ADMIN_VERIFY = "SET_ADMIN_VERIFY";
export const ADMIN_LOGIN = "ADMIN_LOGIN";
export const MAKE_OUTBOUND_CALL = "MAKE_OUTBOUND_CALL";
export const SET_ERROR_MESSAGE = "SET_ERROR_MESSAGE";
export const GET_ASSISTANTS_ID = "GET_ASSISTANTS_ID";
export const GET_NUMBER_ID = "GET_NUMBER_ID";
export const DOCUMENT_ID = "DOCUMENT_ID";
export const SET_ASSISTANTS_ID = "SET_ASSISTANTS_ID";
export const SET_NUMBER_ID = "SET_NUMBER_ID";
export const SET_DOCUMENT_ID = "SET_DOCUMENT_ID";
export const ADD_PROJECT = "ADD_PROJECT";
export const MAKE_OUTBOUND_CALL_CSV = "MAKE_OUTBOUND_CALL_CSV";
export const DOWNLOAD_DATA = "DOWNLOAD_DATA";
export const SET_ADMIN_LOADER = "SET_ADMIN_LOADER";
export const VERIFY_ADMIN_ACTION = "VERIFY_ADMIN_ACTION";
export const GET_PROJECT_DATA = "GET_PROJECT_DATA";
export const SET_PROJECT_DATA = "SET_PROJECT_DATA";
export const LOGOUT_ADMIN = "LOGOUT_ADMIN";
export const IS_ADMIN_LOGIN = "IS_ADMIN_LOGIN";
export const ARCHIVE_PROJECT = "ARCHIVE_PROJECT";
export const SET_ARCHIVE_PROJECT = "SET_ARCHIVE_PROJECT";
export const CREATE_ASSISTANT = "CREATE_ASSISTANT";
export const UPLOAD_DOCUMENT = "UPLOAD_DOCUMENT";
export const DROP_DOCUMENT = "DROP_DOCUMENT";
export const UPLOAD_DOCUMENT_LOADER = "UPLOAD_DOCUMENT_LOADER";
export const DELETE_DOCUMENT = "DELETE_DOCUMENT"
