import { SEARCH_NEW_CHAT, SET_CONVERSION, SET_MESSAGE_ID, SHARE_MODAL } from "./constants";

const defaultState = {
  allConversion: [],
  search: "",
  shareModal: false,
  messageID: ""
};

const chatReducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_CONVERSION:
      return { ...state, allConversion: action.payload };
    case SEARCH_NEW_CHAT:
      return { ...state, search: action.payload };
    case SHARE_MODAL:
      return { ...state, shareModal: action.payload };
    case SET_MESSAGE_ID:
      return { ...state, messageID: action.payload }
    default:
      return state;
  }
};

export default chatReducer;
