import { useClient } from "@xmtp/react-sdk";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { web3authAction } from "./Redux/actions";
// import { useVapi } from "../../features/Assistant/useVapi";
// import {VapiButton} from "../../features/Assistant/vapiButton";
// import { vapi } from "../../features/Assistant/vapi.sdk";
import { setErrorMessage } from "../Vapi/Redux/actions";
import ErrorMessage from "../Vapi/component";
import "./index.css";

function Login(props) {
  const navigate = useNavigate();
  // const { toggleCall, messages, callStatus, audioLevel } =
  // useVapi();

  // useEffect(() => {
  //   vapi.on("message", scrollToBottom);
  //   return () => {
  //     vapi.off("message", scrollToBottom);
  //   };
  // });
  const { client } = useClient();

  const connectWallet = async () => {
    try {

      const messageId = localStorage.getItem("messageID")
      console.log("hitting");
      if (!props.web3auth) {
        console.log("web3auth not initialized yet");
        return;
      }
      if (messageId) {
        localStorage.setItem("messageID", messageId);
      }
      await props.web3auth.connect();

    } catch (err) {
      console.log("err", err);
    }
  };

  const YouTubeVideo = ({ videoId }) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <iframe
          style={{ border: "none", paddingTop: "10px", paddingBottom: "10px" }}
          width="50%"
          height="450"
          src={`https://www.youtube.com/embed/${videoId}?autoplay=1`}
          title="Meet Seriously 360"
          allowFullScreen
        ></iframe>
      </div>
    );
  };



  useEffect(() => {
    if (client) {
      navigate("/chat");
    }
  }, [client]);

  useEffect(() => {
    const handleResize = () => {
      if (window.scrollY > 91) {
        document.getElementsByTagName("header")[0].classList.add("scrolled");
      } else {
        document.getElementsByTagName("header")[0].classList.remove("scrolled");
      }
    };
    window.addEventListener("scroll", handleResize);
    return () => {
      window.removeEventListener("scroll", handleResize);
    };
  }, []);

  return (
    <div className="super_container">
      <header className="header trans_400">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="header_content d-flex flex-row align-items-center justify-content-start trans_400">
                <div className="logo">
                  <div className="headerLogo">
                    Seriously<span>360</span>
                  </div>
                </div>
                <nav className="main_nav ml-auto mr-auto">
                  <ul className="d-flex flex-row align-items-center justify-content-start" />
                </nav>
                <div className="log_reg">
                  <div className="log_reg_content d-flex flex-row align-items-center justify-content-start">
                    <div
                      className="text-center"
                      onClick={() => connectWallet()}
                    >
                      <div
                        className="btn domain_search_button d-flex align-items-center justify-content-center"
                        style={{ margin: "auto", padding: '8px 16px', fontSize: '14px' }}
                      >
                        Private Chat
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <div className="home">
        <div className="home_background"></div>
        <div
          className="background_image background_city"
          style={{
            backgroundImage: `url(${require("../../Images/city.png")})`,
          }}
        ></div>
        <div className="cloud cloud_1">
          <img src="images/cloud.png" alt="" />
        </div>
        <div className="cloud cloud_2">
          <img src="images/cloud.png" alt="" />
        </div>
        <div className="cloud cloud_3">
          <img src="images/cloud_full.png" alt="" />
        </div>
        <div className="cloud cloud_4">
          <img src="images/cloud.png" alt="" />
        </div>
        <div className="home_container">
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="home_content text-center">
                  <div className="home_title">
                    Enjoy secure, private communication with end-to-end encryption, decentralized messaging, and file sharing—protected from eavesdropping.
                    <span style={{ color: "#1befc5" }}></span>
                  </div>
                  <div
                    className="text-center mt-4"
                    onClick={() => connectWallet()}
                  >
                    <div
                      className="btn domain_search_button d-flex align-items-center justify-content-center"
                      style={{ margin: "auto", height: 50 }}
                    >
                      Private Chat
                    </div>
                  </div>
                  {/* <div>
                  <VapiButton
                      audioLevel={audioLevel}
                      callStatus={callStatus}
                      toggleCall={toggleCall}
                    />
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <YouTubeVideo videoId={"ZIzjJw8pMEI"} />
      </div>

      <div className="intro">
        <div className="container">
          <div className="row">
            <div className="col magic_fade_in">
              <div className="section_title text-center">
                <h2>
                  Shift Your Energy: AI Manages the Details, You Drive the
                  Vision.
                </h2>
              </div>
            </div>
          </div>
          <div className="row intro_row">
            <div
              className="intro_dots magic_fade_in"
              style={{
                backgroundImage: `url(${require("../../Images/dots.png")})`,
              }}
            ></div>
            <div className="col-lg-4 intro_col magic_fade_in">
              <div className="intro_item d-flex flex-column align-items-center justify-content-start text-center">
                <div className="intro_icon_container d-flex flex-column align-items-center justify-content-center">
                  <div className="intro_icon">
                    <img
                      src="images/icon_1.svg"
                      alt="https://www.flaticon.com/authors/freepik"
                    />
                  </div>
                </div>
                <div className="intro_item_content">
                  <div className="intro_item_title">
                    Zero Trust as a Service
                  </div>
                  <div className="intro_item_text">
                    <p>
                      Imagine a world where your identity is completely secure
                      and entirely under your control. With our innovative
                      solution, you can prove who you are without ever revealing
                      private information, ensuring your data remains safe and
                      your privacy intact. Experience the freedom of anonymous
                      access with the assurance of verifiability. Take charge
                      with a decentralized identity that empowers you, not
                      external entities. Choose our technology today and
                      transform how you protect and present your digital self in
                      a connected world. Step into security, step into freedom.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 intro_col magic_fade_in">
              <div className="intro_item d-flex flex-column align-items-center justify-content-start text-center">
                <div className="intro_icon_container d-flex flex-column align-items-center justify-content-center">
                  <div className="intro_icon">
                    <img
                      src="images/icon_2.svg"
                      alt="https://www.flaticon.com/authors/freepik"
                    />
                  </div>
                </div>
                <div className="intro_item_content">
                  <div className="intro_item_title">
                    AI Workforce Efficiency:
                  </div>
                  <div className="intro_item_text">
                    <p>
                      Empower your business with AI Workforce Agents designed to
                      revolutionize the way you work. Automate routine tasks to
                      boost efficiency and streamline your operations with
                      intelligent task handling. Amplify your reach and engage
                      with customers effortlessly, securing more leads and
                      scaling your outreach—all without the need to expand your
                      payroll. Embrace the future of work and let our AI
                      Workforce Agents take your business to the next level of
                      operational excellence and customer engagement.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            ={" "}
            <div className="col-lg-4 intro_col magic_fade_in">
              <div className="intro_item d-flex flex-column align-items-center justify-content-start text-center">
                <div className="intro_icon_container d-flex flex-column align-items-center justify-content-center">
                  <div className="intro_icon">
                    <img
                      src="images/icon_3.svg"
                      alt="https://www.flaticon.com/authors/freepik"
                    />
                  </div>
                </div>
                <div className="intro_item_content">
                  <div className="intro_item_title">Private Conversations</div>
                  <div className="intro_item_text">
                    <p>
                      Step into a world where your conversations are fortified
                      with end-to-end encryption, allowing you to share
                      important communications and files without a second
                      thought. Our solution ensures complete data sovereignty,
                      giving you full control over your information. With
                      decentralized messaging and robust E2EE security, you're
                      not just sending messages—you're safeguarding every word.
                      Empower your communication with unparalleled security and
                      take control of your data’s destiny.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col text-center">
              <div
                className="intro_button text-center trans_200 ml-auto mr-auto"
                onClick={() => connectWallet()}
              >
                <div className="e2eeMessage">Private Chat</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="services">
        <div
          className="parallax_background parallax-window"
          data-parallax="scroll"
          style={{
            backgroundImage: `url(${require("../../Images/services.jpg")})`,
          }}
          data-speed="0.8"
        ></div>
        <div className="container">
          <div className="row">
            <div className="col magic_fade_in">
              <div className="section_title text-center">
                <h2>Our Services</h2>
              </div>
            </div>
          </div>
          <div className="row services_row">
            <div className="col-lg-4 service_col magic_fade_in">
              <div className="service d-flex flex-column align-items-center justify-content-start text-center trans_200">
                <div className="service_icon">
                  <img
                    className="svg"
                    src="images/icon_4.svg"
                    alt="https://www.flaticon.com/authors/freepik"
                  />
                </div>
                <div className="service_title">
                  <h3>Frictionless Onboarding</h3>
                </div>
                <div className="service_text">
                  <p>
                    Experience seamless integration into our ecosystem with Easy
                    Frictionless Onboarding. This service is designed to
                    simplify your transition, ensuring immediate access to our
                    solutions with minimal effort and maximum efficiency.
                  </p>
                </div>
                <div
                  className="service_button trans_200"
                  onClick={() => connectWallet()}
                >
                  <div className="e2eeChat">Start E2EE Chat</div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 service_col magic_fade_in">
              <div className="service d-flex flex-column align-items-center justify-content-start text-center trans_200">
                <div className="service_icon">
                  <img
                    className="svg"
                    src="images/icon_6.svg"
                    alt="https://www.flaticon.com/authors/freepik"
                  />
                </div>
                <div className="service_title">
                  <h3>Reduce Storage Costs</h3>
                </div>
                <div className="service_text">
                  <p>
                    Optimize your digital asset management with our Data
                    Migration service. Tailored to streamline your storage
                    solutions, it reduces costs while enhancing security,
                    allowing for a smarter allocation of your digital resources.
                  </p>
                </div>
                <div
                  className="service_button trans_200"
                  onClick={() => connectWallet()}
                >
                  <div className="e2eeChat">Start E2EE Chat</div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 service_col magic_fade_in">
              <div className="service d-flex flex-column align-items-center justify-content-start text-center trans_200">
                <div className="service_icon">
                  <img
                    className="svg"
                    src="images/icon_5.svg"
                    alt="https://www.flaticon.com/authors/freepik"
                  />
                </div>
                <div className="service_title">
                  <h3>Branded Messaging</h3>
                </div>
                <div className="service_text">
                  <p>
                    Elevate your brand’s communication with our Branded E2EE
                    Messaging. Offering the pinnacle of privacy, this service
                    enables secure conversations under your brand, fostering
                    trust and confidentiality with every message sent.
                  </p>
                </div>
                <div
                  className="service_button trans_200"
                  onClick={() => connectWallet()}
                >
                  <div className="e2eeChat">Start E2EE Chat</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* <div className="choice">
        <div className="container">
          <div className="row row-lg-eq-height">
            <div className="col-lg-6 choice_col magic_fade_in">
              <div className="choice_image">
                <img src="images/choice.png" alt="" />
              </div>
            </div>

            <div className="col-lg-6 choice_col magic_fade_in">
              <div className="choice_content">
                <div className="section_title magic_fade_in">
                  <h2>Why Choose us?</h2>
                </div>
                <div className="choice_text">
                  <p>
                    At the core of our ethos is the belief in working alongside
                    our customers as partners. We don't just offer services; we
                    embark on a journey of mutual growth and success. Our
                    approach is built on collaboration, understanding your
                    unique needs, and tailoring our innovative solutions to fit
                    those needs perfectly. Together, we strive to overcome
                    challenges and celebrate victories, ensuring that your
                    success is our success. Choose us to not just be another
                    client, but to be a partner in shaping a secure,
                    decentralized future.
                  </p>
                </div>
                <div className="choice_list">
                  <ul className="d-flex flex-row align-items-start justify-content-between">
                    <li className="d-flex flex-column align-items-center justify-content-center magic_fade_in">
                      <div className="choice_icon">
                        <img
                          src="images/icon_7.svg"
                          alt="https://www.flaticon.com/authors/freepik"
                        />
                      </div>
                      <div className="choice_title">Identity</div>
                    </li>
                    <li className="d-flex flex-column align-items-center justify-content-center magic_fade_in">
                      <div className="choice_icon">
                        <img
                          src="images/icon_8.svg"
                          alt="https://www.flaticon.com/authors/freepik"
                        />
                      </div>
                      <div className="choice_title">Scalability</div>
                    </li>
                    <li className="d-flex flex-column align-items-center justify-content-center magic_fade_in">
                      <div className="choice_icon">
                        <img
                          src="images/icon_9.svg"
                          alt="https://www.flaticon.com/authors/freepik"
                        />
                      </div>
                      <div className="choice_title">Sovereignty</div>
                    </li>
                    <li className="d-flex flex-column align-items-center justify-content-center magic_fade_in">
                      <div className="choice_icon">
                        <img
                          src="images/icon_10.svg"
                          alt="https://www.flaticon.com/authors/freepik"
                        />
                      </div>
                      <div className="choice_title">Security</div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* <div className="cta">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="cta_content d-flex flex-lg-row flex-column align-items-center justify-content-lg-between justify-content-center magic_fade_in">
                <div className="cta_title">
                  Need assistance? Send us a E2EE message
                </div>
                <div className="cta_button" onClick={() => connectWallet()}>
                  <div className="sendBtn">Send</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <footer className="footer">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 footer_col">
              <div className="footer_about">
                <div className="footer_logo">
                  Seriously<span>360</span>
                </div>
                <div className="footer_text">
                  <p>
                    At Seriously 360, we're on a mission to transform workforces
                    with AI, making operations more efficient and secure. We're
                    always open to new partnerships and collaborations. If
                    you're interested in revolutionizing the way we work
                    together, reach out to us. Let's innovate and grow together.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-6 footer_col">
              <div className="footer_links">
                <div className="row"></div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <ErrorMessage
        message={props.message}
        setErrorMessage={props.setErrorMessage}
      />
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    web3authAction: (values) => {
      dispatch(web3authAction(values));
    },
    setErrorMessage: (values) => {
      dispatch(setErrorMessage(values));
    },
  };
};

const mapStateToProps = (state) => {
  return {
    web3auth: state.loginReducer.web3auth,
    message: state.adminReducer.message,
    messageID: state.chatReducer.messageID
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Login);
