import "./polyfill";

import {
  ContentTypeScreenEffect,
  ScreenEffectCodec,
} from "@xmtp/experimental-content-type-screen-effect";
import {
  XMTPProvider,
  attachmentContentTypeConfig,
  reactionContentTypeConfig,
  replyContentTypeConfig,
} from "@xmtp/react-sdk";
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import store from "./store/store";

const DB_VERSION = 5;

export const ScreenEffectCodecInstance = new ScreenEffectCodec();

const customConfig = {
  codecs: [ScreenEffectCodecInstance],
  contentTypes: [ContentTypeScreenEffect.toString()],
  namespace: "screenEffects",
};

const contentTypeConfigs = [
  attachmentContentTypeConfig,
  reactionContentTypeConfig,
  replyContentTypeConfig,
  customConfig,
];

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <XMTPProvider
      contentTypeConfigs={contentTypeConfigs}
      dbVersion={DB_VERSION}
    >
      <Provider store={store}>
        <App />
      </Provider>
    </XMTPProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
