import { LOADER_ACTION, WEB_3_AUTH } from "./constants";

const defaultState = {
  web3auth: "",
  loader: false,
};

const loginReducer = (state = defaultState, action) => {
  switch (action.type) {
    case WEB_3_AUTH:
      return { ...state, web3auth: action.payload };
    case LOADER_ACTION:
      return { ...state, loader: action.payload };

    default:
      return state;
  }
};

export default loginReducer;
