import { call, put, select, takeLatest } from "redux-saga/effects";
import {
    dropDocumentAction,
    setAdminLoaderAction,
    setAdminVerifyAction,
    setArchiveProjectAction,
    setErrorMessage,
    setListAssistants,
    setListDocument,
    setListNumber,
    setProjectData,
    uploadDocumentLoader,
} from "./actions";
import {
    addProjectApi,
    adminLoginApi,
    archiveOrUndoApi,
    createAssistantApi,
    deleteDocumentApi,
    downloadDataApi,
    getListAssistantsApi,
    getListDocumentApi,
    getListNumbersApi,
    getProjectDataApi,
    makeOutboundCallApi,
    makeOutboundCallCSVApi,
    uploadDocumentApi,
    verifyAdminApi,
} from "./api";
import {
    ADD_PROJECT,
    ADMIN_LOGIN,
    ARCHIVE_PROJECT,
    CREATE_ASSISTANT,
    DELETE_DOCUMENT,
    DOCUMENT_ID,
    DOWNLOAD_DATA,
    GET_ASSISTANTS_ID,
    GET_NUMBER_ID,
    GET_PROJECT_DATA,
    IS_ADMIN_LOGIN,
    LOGOUT_ADMIN,
    MAKE_OUTBOUND_CALL,
    MAKE_OUTBOUND_CALL_CSV,
    UPLOAD_DOCUMENT,
    VERIFY_ADMIN_ACTION,
} from "./constant";

function* adminLoginSaga(action) {
    try {
        const response = yield call(adminLoginApi, action.payload.loginData);

        if (response && response.status === 200) {
            yield put(setAdminVerifyAction(true));
            localStorage.setItem("userID", response.data.data);
            yield put(
                setErrorMessage({
                    message: "Login successful",
                    status: "success",
                })
            );
            action.payload.navigate("/call");
        } else {
            yield put(
                setErrorMessage({
                    message: "Invalid credentials",
                    status: "error",
                })
            );
            // action.payload.navigate("/");
        }
    } catch (e) {
        console.log(e);
    }
}

function* createAssistantSaga(action) {
    try {
        yield put(setAdminLoaderAction(true));
        const formData = action.payload.formData;
        const body = { ...formData };
        if (formData.documents === "NONE") delete body.documents;
        console.log(body);
        const res = yield call(createAssistantApi, body);
        if (!res || (res && res.status !== 200)) {
            yield put(
                setErrorMessage({
                    message: "Something went wrong",
                    status: "error",
                })
            );
            return;
            // throw new Error("API CALL FAILED");
        }
        yield put(
            setErrorMessage({
                message: "Assistant Crearted Successfully",
                status: "success",
            })
        );
        action.payload.navigate("/project");
    } catch (e) {
        console.log(e);
        yield put(
            setErrorMessage({
                message: e?.response?.data?.message || "Something went wrong",
                status: "warning",
            })
        );
    } finally {
        yield put(setAdminLoaderAction(false));
    }
}

function* makeOutboundCallSaga(action) {
    try {
        // const form = new FormData();

        // form.append("assistantName", action.payload.assistantName);

        // form.append("phoneNumberId", action.payload.phoneNumberId);
        // form.append("customerNumber", action.payload.customerNumber);
        // form.append("responseDelaySeconds", action.payload.responseDelaySeconds);
        // form.append("isPhoneCall", action.payload.isPhoneCall);

        // form.append("isPhoneCall", action.payload.formData.isPhoneCall)

        const response = yield call(makeOutboundCallApi, action.payload);

        if (response.status === 200) {
            console.log("done");
            yield put(
                setErrorMessage({
                    message: "Outbound call successful!",
                    status: "success",
                })
            );
        } else {
            yield put(
                setErrorMessage({
                    message: `${response.data.message}`,
                    status: "error",
                })
            );
        }
    } catch (e) {
        console.log(e);
    }
}

function* getListAssistantsSaga(action) {
    try {
        const response = yield call(getListAssistantsApi, action.payload);
        if (response && response.status === 200) {
            yield put(setListAssistants(response.data.data));
        }
    } catch (e) {
        console.log(e);
    }
}

function* getListNumberSaga(action) {
    try {
        const response = yield call(getListNumbersApi, action.payload);
        if (response && response.status === 200) {
            yield put(setListNumber(response.data.data));
        }
    } catch (e) {
        console.log(e);
    }
}

function* addProjectSaga(action) {
    try {
        const form = new FormData();

        form.append("csv", action.payload.formData.customerNumber);
        form.append("assistantId", action.payload.formData.assistantId);
        form.append("phoneNumberId", action.payload.formData.phoneNumberId);
        form.append("projectName", action.payload.formData.projectName);
        form.append("isPhoneCall", action.payload.formData.isPhoneCall);

        const response = yield call(addProjectApi, form);

        if (response && response.status === 200) {
            yield put(
                setErrorMessage({
                    message: response.data.message,
                    status: "success",
                })
            );
            action.payload.navigate("/call");
        } else {
            yield put(
                setErrorMessage({
                    message: response.data.message,
                    status: "error",
                })
            );
        }
    } catch (e) {
        console.log(e);
    }
}

function* makeOutboundCallCSVSaga(action) {
    try {
        const response = yield call(makeOutboundCallCSVApi, action.payload);
        if (response && response.status === 200) {
            yield put(
                setErrorMessage({
                    message: response.data.message,
                    status: "success",
                })
            );
        }
    } catch (e) {
        console.log(e);
    }
}

function* downloadDataSaga(action) {
    try {
        const response = yield call(downloadDataApi);
        if (response && response.status === 200) {
            console.log("response");
            response.json();
        }
    } catch (e) {
        console.log(e);
    }
}

function* verifyAdminSaga(action) {
    try {
        const adminId = localStorage.getItem("userID");
        if (!adminId) {
            yield put(
                setErrorMessage({
                    message: " Please login to Your account",
                    status: "warning",
                })
            );
            action.payload.navigate("/admin");
            return;
        }
        const response = yield call(verifyAdminApi, { userID: adminId });
        if (response && response.status === 200) {
            yield put(setAdminVerifyAction(true));
            yield put(setAdminLoaderAction(false));
            return;
        } else if (response && response.status === 400) {
            yield put(setAdminVerifyAction(false));
            yield put(
                setErrorMessage({
                    message: "!Invalid User",
                    status: "error",
                })
            );
            action.payload.navigate("/");
            return;
        } else {
            yield put(setAdminVerifyAction(false));
            yield put(
                setErrorMessage({
                    message: "!Invalid User",
                    status: "error",
                })
            );
            action.payload.navigate("/");
            return;
        }
    } catch (e) {
        console.log(e);
    }
}

function* getProjectDataSaga(action) {
    try {
        const response = yield call(getProjectDataApi);
        if (response && response.status === 200) {
            const archiveProject = response.data.data.filter(
                (e) => e.isArchive === true
            );
            const project = response.data.data.filter((e) => e.isArchive === false);
            yield put(setProjectData(project));
            yield put(setArchiveProjectAction(archiveProject));
        }
    } catch (e) {
        console.log(e);
    }
}

function* logoutAdminSaga(action) {
    try {
        localStorage.clear("userID");
        yield put(
            setErrorMessage({
                message: "Logout Successfully",
                status: "info",
            })
        );
        action.payload.navigate("/");
    } catch (e) {
        console.log(e);
    }
}

function* isAdminLoginSaga(action) {
    try {
        const adminId = localStorage.getItem("userID");
        if (!adminId) {
            yield put(setAdminLoaderAction(false));
            return;
        }

        const response = yield call(verifyAdminApi, { userID: adminId });
        if (response && response.status === 200) {
            yield put(setAdminVerifyAction(true));
            action.payload.navigate("/table");
            return;
        }
        if (response && response.status === 400) {
            yield put(setAdminVerifyAction(false));
            yield put(setAdminLoaderAction(false));
            return;
        } else {
            yield put(setAdminVerifyAction(false));
            yield put(setAdminLoaderAction(false));
            return;
        }
    } catch (e) {
        console.log(e);
    }
}

function* archiveProjectSaga(action) {
    try {
        const { adminReducer } = yield select();
        const response = yield call(archiveOrUndoApi, action.payload);
        if (response && response.status === 200) {
            if (action.payload.isArchive) {
                const filterProjectData = adminReducer.projectData.filter(
                    (e) => e.projectID !== action.payload.projectID
                );
                // const filterProjectData2 = adminReducer.projectData.filter((e) => e.projectID === action.payload.projectID)

                // const filterProjectDataForArchive = [...adminReducer.archiveProject, ...filterProjectData2]
                yield put(setProjectData(filterProjectData));
                // yield put(setArchiveProjectAction(filterProjectDataForArchive))
            } else {
                const filterProjectData = adminReducer.archiveProject.filter(
                    (e) => e.projectID !== action.payload.projectID
                );
                // const filterProjectData2 = adminReducer.archiveProject.filter((e) => e.projectID === action.payload.projectID)
                // const filterProjectDataForProject = [...adminReducer.projectData, ...filterProjectData2]
                yield put(setArchiveProjectAction(filterProjectData));
                // yield put(setProjectData(filterProjectDataForProject))
            }
        } else {
            yield put(
                setErrorMessage({
                    message: "Something went wrong",
                    status: "error",
                })
            );
        }
    } catch (e) {
        console.log(e);
    }
}

// function* createAssistantSaga(action) {
//   try {
//     const response = yield call(createAssistantApi, action.payload);
//     if (response && response.status === 200) {
//       console.log(response);
//     }
//   } catch (e) {
//     console.log(e);
//   }
// }

function* uploadDocumentSaga(action) {
    try {
        const { adminReducer } = yield select();
        const form = new FormData();
        form.append("pdf", action.payload.document);
        yield put(uploadDocumentLoader(true));

        const response = yield call(uploadDocumentApi, form);

        if (response && response.status === 200) {
            console.log(response);

            const addDocument = [...adminReducer.documentIDs, response.data.data];

            yield put(setListDocument(addDocument));
            yield put(dropDocumentAction(null));
            yield put(
                setErrorMessage({
                    message: "Upload document successful!",
                    status: "success",
                })
            );
            yield put(uploadDocumentLoader(false));
            action.payload.navigate("/listDocument");
        }
        if (response && response.status === 400) {
            yield put(uploadDocumentLoader(false));
            yield put(
                setErrorMessage({
                    message: "Document Upload failed!",
                    status: "error",
                })
            );
        }

        yield put(uploadDocumentLoader(false));
    } catch (e) {
        yield put(uploadDocumentLoader(false));
        console.log(e);
    }
}

function* getListDocumentsSaga(action) {
    try {
        const response = yield call(getListDocumentApi, action.payload);
        if (response && response.status === 200) {
            yield put(setListDocument(response.data.data));
        }
    } catch (e) {
        console.log(e);
    }
}

function* deleteDocumentSaga(action) {
    try {
        debugger;
        const { adminReducer } = yield select();
        const response = yield call(deleteDocumentApi, action.payload);

        if (response && response.status === 200) {
            const filterDocumentList = adminReducer.documentIDs.filter(
                (e) => action.payload.id !== e.id
            );
            yield put(setListDocument(filterDocumentList));
            yield put(
                setErrorMessage({
                    message: " Document delete successful!",
                    status: "success",
                })
            );
            console.log("success");
        }
    } catch (e) {
        console.log(e);
    }
}

function* adminSaga() {
    yield takeLatest(ADMIN_LOGIN, adminLoginSaga);
    yield takeLatest(MAKE_OUTBOUND_CALL, makeOutboundCallSaga);
    yield takeLatest(GET_ASSISTANTS_ID, getListAssistantsSaga);
    yield takeLatest(GET_NUMBER_ID, getListNumberSaga);
    yield takeLatest(ADD_PROJECT, addProjectSaga);
    yield takeLatest(MAKE_OUTBOUND_CALL_CSV, makeOutboundCallCSVSaga);
    yield takeLatest(DOWNLOAD_DATA, downloadDataSaga);
    yield takeLatest(VERIFY_ADMIN_ACTION, verifyAdminSaga);
    yield takeLatest(GET_PROJECT_DATA, getProjectDataSaga);
    yield takeLatest(LOGOUT_ADMIN, logoutAdminSaga);
    yield takeLatest(IS_ADMIN_LOGIN, isAdminLoginSaga);
    yield takeLatest(ARCHIVE_PROJECT, archiveProjectSaga);
    //   yield takeLatest(CREATE_ASSISTANT, createAssistantSaga);
    yield takeLatest(UPLOAD_DOCUMENT, uploadDocumentSaga);
    yield takeLatest(DOCUMENT_ID, getListDocumentsSaga);
    yield takeLatest(DELETE_DOCUMENT, deleteDocumentSaga);
    yield takeLatest(CREATE_ASSISTANT, createAssistantSaga);
}

export default adminSaga;
