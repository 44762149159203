import AddIcon from "@mui/icons-material/Add";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
    Button,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { tableCellClasses } from "@mui/material/TableCell";
import TablePagination from "@mui/material/TablePagination";
import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
    deleteDocument,
    getListDocument,
    setErrorMessage,
} from "../Redux/actions";
import ErrorMessage from "../component";

const ListDocument = (props) => {
    // const [isDocument, setIsDocument] = useState(false)
    const [sortedData, setSortedData] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const navigate = useNavigate();

    useEffect(() => {
        props.getListDocument();
    }, []);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(() => {
        if (props.documentIDs) {
            setSortedData([...props.documentIDs]);
        }
    }, [props.documentIDs]);

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
            fontSize: 18,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));

    return props.documentIDs ? (
        <>
            {/* <Sidebar /> */}

            {sortedData?.length > 0 ? (
                <Paper sx={{ width: "100%", overflow: "hidden" }}>
                    <div
                        style={{
                            textAlign: "right",
                            marginTop: "40px",
                            marginRight: "20px",
                        }}
                    >
                        {" "}
                        <Button
                            variant="outlined"
                            startIcon={<AddIcon />}
                            onClick={() => {
                                props.setErrorMessage([]);
                                // setIsDocument(true)
                                navigate("/upload");
                            }}
                            component={Button}
                        >
                            add document
                        </Button>
                    </div>

                    <TableContainer sx={{ maxHeight: 660, mt: "62px" }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>Name</StyledTableCell>
                                    <StyledTableCell>Action</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {sortedData
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row, ind) => (
                                        <TableRow
                                            hover
                                            key={row._id}
                                            style={{
                                                backgroundColor: ind % 2 === 0 ? "#f0f0f0" : "#f0fff0",
                                            }}
                                        >
                                            <TableCell>{row.name}</TableCell>
                                            <TableCell>
                                                <a href={row.url} target="_blank" download>
                                                    <Button size="small" color="primary">
                                                        <VisibilityIcon />
                                                    </Button>
                                                </a>

                                                <DeleteOutlineIcon
                                                    onClick={() => {
                                                        props.deleteDocument({ id: row.id });
                                                    }}
                                                    sx={{ color: "red", cursor: "pointer" }}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={sortedData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                    <ErrorMessage
                        message={props.message}
                        setErrorMessage={props.setErrorMessage}
                    />
                </Paper>
            ) : (
                <div
                    style={{
                        textAlign: "center",
                        marginTop: "340px",
                    }}
                >
                    {" "}
                    <h4>No Document Available</h4>
                    <Button
                        variant="outlined"
                        startIcon={<AddIcon />}
                        onClick={() => {
                            props.setErrorMessage([]);
                            navigate("/upload");
                        }}
                        component={Button}
                    >
                        add document
                    </Button>
                </div>
            )}
        </>
    ) : (
        <div>
            <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        getListDocument: (values) => {
            dispatch(getListDocument(values));
        },
        setErrorMessage: (values) => {
            dispatch(setErrorMessage(values));
        },
        deleteDocument: (values) => {
            dispatch(deleteDocument(values));
        },
    };
};

const mapStateToProps = (state) => {
    return {
        documentIDs: state.adminReducer.documentIDs,
        message: state.adminReducer.message,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ListDocument);
