
import SideBar from '../sidebar/index';
import "./Layout.css";

const Layout = (Component) => ({ ...props }) => {
    return (
        <div className="layout">
            <SideBar />
            <div className="main-container">
                <Component {...props} />
            </div>
        </div>
    )
}


export default Layout;