import {
    DROP_DOCUMENT,
    SET_ADMIN_LOADER,
    SET_ADMIN_VERIFY,
    SET_ARCHIVE_PROJECT,
    SET_ASSISTANTS_ID,
    SET_DOCUMENT_ID,
    SET_ERROR_MESSAGE,
    SET_NUMBER_ID,
    SET_PROJECT_DATA,
    UPLOAD_DOCUMENT_LOADER,
} from "./constant";

const defaultState = {
    adminVerify: false,
    message: {message: undefined, status: undefined},
    assistantIDs: "",
    numberIDs: "",
    documentIDs: [],
    loader: true,
    projectData: "",
    archiveProject: "",
    dropDocument: null,
    documentLoader: false
};

const adminReducer = (state = defaultState, action) => {
    switch (action.type) {
        case SET_ADMIN_VERIFY:
            return { ...state, adminVerify: action.payload };
        case SET_ERROR_MESSAGE:
            return { ...state, message: action.payload };
        case SET_NUMBER_ID:
            return { ...state, numberIDs: action.payload };
        case SET_ASSISTANTS_ID:
            return { ...state, assistantIDs: action.payload };
        case SET_DOCUMENT_ID:
            return { ...state, documentIDs: action.payload };
        case SET_ADMIN_LOADER:
            return { ...state, loader: action.payload };
        case SET_PROJECT_DATA:
            return { ...state, projectData: action.payload };
        case SET_ARCHIVE_PROJECT:
            return { ...state, archiveProject: action.payload };
        case DROP_DOCUMENT:
            return { ...state, dropDocument: action.payload };
        case UPLOAD_DOCUMENT_LOADER:
            return { ...state, documentLoader: action.payload }
        default:
            return state;
    }
};

export default adminReducer;
