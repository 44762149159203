import { Button, TextField } from "@mui/material";
import Grid from "@mui/material/Grid";
import React, { useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { adminLoginAction, setErrorMessage } from "./Redux/actions";
import "./Vapi.css";
import ErrorMessage from "./component";

function AdminLogin(props) {


    const navigate = useNavigate();


    const [loginData, setLoginData] = useState({
        email: "",
        password: "",
    });
    const [loginError, setLoginError] = useState({});

    const handleLogin = (e) => {
        const newErrors = {};
        if (loginData.email.trim() === "") {
            newErrors.email = "Email is required";
        }

        if (loginData.email && !/\S+@\S+\.\S+/.test(loginData.email)) {
            newErrors.email = "Invalid Email";
        }
        if (loginData.password.trim() === "") {
            newErrors.password = "Password is required";
        }
        setLoginError(newErrors);

        if (Object.keys(newErrors).length === 0) {

            props.adminLoginAction({ loginData: loginData, navigate: navigate })
        }
    };


    return (
        <>
            <div className="login_form">
                <div>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Email"
                                variant="outlined"
                                value={loginData.email}
                                onChange={(e) => {
                                    setLoginData((pre) => ({ ...pre, email: e.target.value }));
                                    setLoginError((pre) => ({ ...pre, email: "" }));
                                }}
                                error={Boolean(loginError.email)}
                                helperText={loginError.email}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Password"
                                variant="outlined"
                                type="password"
                                value={loginData.password}
                                error={Boolean(loginError.password)}
                                onChange={(e) => {
                                    setLoginData((pre) => ({ ...pre, password: e.target.value }));
                                    setLoginError((pre) => ({ ...pre, password: "" }));
                                }}

                                helperText={loginError.password}
                            />
                        </Grid>
                        <Grid item xs={12} textAlign={"center"}>
                            <Button variant="contained" color="primary" onClick={handleLogin}>
                                Login
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            </div>
            <ErrorMessage message={props.message} setErrorMessage={props.setErrorMessage} />
        </>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        adminLoginAction: (values) => {
            dispatch(adminLoginAction(values));
        },
        setErrorMessage: (values) => {
            dispatch(setErrorMessage(values))
        },
    };
};

const mapStateToProps = (state) => {
    return {
        message: state.adminReducer.message,
        adminVerify: state.adminReducer.adminVerify
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminLogin);
