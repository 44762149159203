
import DownloadIcon from "@mui/icons-material/Download";
import DownloadingIcon from "@mui/icons-material/Downloading";
import FileDownloadDoneIcon from "@mui/icons-material/FileDownloadDone";
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import {
    Button,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Link from '@mui/material/Link';
import { tableCellClasses } from "@mui/material/TableCell";
import TablePagination from "@mui/material/TablePagination";
import { styled } from "@mui/material/styles";
import axios from "axios";
import { saveAs } from "file-saver";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { API } from "../../../utils/config";

import {
    archiveProjectAction,
    getProjectDataAction,
    makeOutboundCallCSVAction,
    setErrorMessage,
} from "../Redux/actions";
import ErrorMessage from '../component';

const ArchiveProject = (props) => {

    const [sortedData, setSortedData] = useState([]);
    const [sortConfig, setSortConfig] = useState({
        field: null,
        direction: null,
    });

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5); // Number of rows per page
    const [jsonData, setJsonData] = useState();
    const [downloadDataRow, setDownloadDataRow] = useState({});

    const [downloadCustomerNumberRow, setDownloadCustomerNumberRow] = useState({});
    const [downloadCalledNumberRow, setDownloadCalledNumberRow] = useState({});
    const [downloadRemainNumberRow, setDownloadRemainNumberRow] = useState({});



    const navigate = useNavigate();



    useEffect(() => {
        props.getProjectDataAction();
    }, []);

    useEffect(() => {
        if (props.archiveProject) {
            setSortedData([...props.archiveProject]);
        }
    }, [props.archiveProject]);

    const downloadData = async (e, row) => {

        const id = row.projectID;
        setDownloadDataRow((pre) => {
            const newState = { ...pre };
            newState[id] = "downloading";
            return newState;
        });
        props.setErrorMessage([]);
        const response = await axios.get(`${API}download_data/${id}`);
        const jsonData = response.data;
        setJsonData(jsonData);
        if (jsonData) {
            setDownloadDataRow((pre) => {
                const newState = { ...pre };
                newState[id] = "downloaded";
                return newState;
            });
        }
        const jsonBlob = new Blob([JSON.stringify(jsonData, null, 2)], {
            type: "application/json",
        });
        saveAs(jsonBlob, "data.json");
    };

    const downloadCustomerNumber = async (e, row) => {


        let customerNumbers = row.customerNumbers
        const csvContent = `customerNumbers - ${row.projectName}` + "\n" + customerNumbers.join("\n");
        const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
        saveAs(blob, `customerNumbers-${row.projectName}.csv`);

    }

    const downloadCalledNumber = async (e, row) => {

        let calledNumbers = row.called
        const csvContent = `calledNumbers - ${row.projectName}` + "\n" + calledNumbers.join("\n");
        const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
        saveAs(blob, `calledNumbers-${row.projectName}.csv`);

    }

    const downloadRemainNumber = async (e, row) => {

        let remainCallNumbers = row.remainCall
        const csvContent = `remainCall - ${row.projectName}` + "\n" + remainCallNumbers.join("\n");
        const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
        saveAs(blob, `remainCall-${row.projectName}.csv`);

    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    const handleSort = (field) => {
        let direction = "asc";
        if (sortConfig.field === field && sortConfig.direction === "asc") {
            direction = "desc";
        }
        setSortConfig({ field, direction });

        const sorted = [...sortedData].sort((a, b) => {
            if (direction === "asc") {
                return a[field] < b[field] ? -1 : 1;
            } else {
                return a[field] > b[field] ? -1 : 1;
            }
        });
        setSortedData(sorted);
    };

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
            fontSize: 18,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));

    return props.archiveProject ? (
        <>
            {/* <Sidebar /> */}

            {sortedData.length > 0 ? (<Paper sx={{ width: "100%", overflow: "hidden" }}>
                <TableContainer sx={{ maxHeight: 660, mt: "62px" }}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell onClick={() => handleSort("projectName")}>
                                    Project Name
                                </StyledTableCell>
                                <StyledTableCell onClick={() => handleSort("createdAt")}>
                                    Created At
                                </StyledTableCell>
                                <StyledTableCell>Customer Number</StyledTableCell>
                                <StyledTableCell>Called Number</StyledTableCell>
                                <StyledTableCell>Remain Number</StyledTableCell>

                                <StyledTableCell>History</StyledTableCell>
                                <StyledTableCell>Unarchive</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sortedData
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, ind) => (
                                    <TableRow
                                        hover
                                        key={row._id}
                                        style={{
                                            backgroundColor:
                                                ind % 2 === 0 ? "#f0f0f0" : "#f0fff0",
                                        }}
                                    >
                                        <TableCell>{row.projectName}</TableCell>
                                        <TableCell>
                                            {moment(row.createdAt)
                                                .utc()
                                                .format("YYYY-MM-DD HH:mm:ss")}
                                        </TableCell>
                                        <TableCell sx={{ cursor: "pointer", textAlign: 'center' }} onClick={(e) => downloadCustomerNumber(e, row)} >

                                            <Link
                                                component="button"
                                                variant="body1"

                                            >
                                                {row.customerNumbers?.length}
                                            </Link>
                                        </TableCell>
                                        <TableCell sx={{ cursor: "pointer", textAlign: 'center' }} onClick={(e) => downloadCalledNumber(e, row)}>

                                            <Link
                                                component="button"
                                                variant="body1"

                                            >
                                                {row.called?.length}
                                            </Link>
                                        </TableCell>
                                        <TableCell sx={{ cursor: "pointer", textAlign: 'center' }} onClick={(e) => downloadRemainNumber(e, row)}>

                                            <Link
                                                component="button"
                                                variant="body1"

                                            >
                                                {row.remainCall?.length}
                                            </Link>
                                        </TableCell>

                                        <TableCell
                                            sx={{ cursor: "pointer", textAlign: 'center' }}
                                            onClick={(e) => downloadData(e, row)}
                                        >
                                            {downloadDataRow[row.projectID] === "downloading" ? (
                                                <DownloadingIcon />
                                            ) : downloadDataRow[row.projectID] ===
                                                "downloaded" ? (
                                                <FileDownloadDoneIcon />
                                            ) : (
                                                <DownloadIcon />
                                            )}
                                        </TableCell>

                                        <TableCell
                                            sx={{ cursor: "pointer", textAlign: 'center' }}
                                            onClick={() => props.archiveProjectAction({ isArchive: false, projectID: row.projectID })}
                                        >
                                            < UnarchiveIcon />
                                        </TableCell>

                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={sortedData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
                <ErrorMessage message={props.message} setErrorMessage={props.setErrorMessage} />
            </Paper >) : (<div style={{
                textAlign: "center", marginTop: '340px',
            }}>
                {" "}
                < Button
                    variant="outlined"
                    component={Button}

                >
                    No Archive Projects
                </Button>


            </div >)}

        </>
    ) : (
        <div>
            <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        getProjectDataAction: (values) => {
            dispatch(getProjectDataAction(values));
        },
        makeOutboundCallCSVAction: (values) => {
            dispatch(makeOutboundCallCSVAction(values));
        },
        setErrorMessage: (values) => {
            dispatch(setErrorMessage(values));
        },
        archiveProjectAction: (values) => {
            dispatch(archiveProjectAction(values))
        }
    };
};

const mapStateToProps = (state) => {
    return {
        archiveProject: state.adminReducer.archiveProject,
        message: state.adminReducer.message
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(ArchiveProject);
