import {
    ADD_PROJECT,
    ADMIN_LOGIN,
    ARCHIVE_PROJECT,
    CREATE_ASSISTANT,
    DELETE_DOCUMENT,
    DOCUMENT_ID,
    DOWNLOAD_DATA,
    DROP_DOCUMENT,
    GET_ASSISTANTS_ID,
    GET_NUMBER_ID,
    GET_PROJECT_DATA,
    IS_ADMIN_LOGIN,
    LOGOUT_ADMIN,
    MAKE_OUTBOUND_CALL,
    MAKE_OUTBOUND_CALL_CSV,
    SET_ADMIN_LOADER,
    SET_ADMIN_VERIFY,
    SET_ARCHIVE_PROJECT,
    SET_ASSISTANTS_ID,
    SET_DOCUMENT_ID,
    SET_ERROR_MESSAGE,
    SET_NUMBER_ID,
    SET_PROJECT_DATA,
    UPLOAD_DOCUMENT,
    UPLOAD_DOCUMENT_LOADER,
    VERIFY_ADMIN_ACTION,
} from "./constant";

export const setAdminVerifyAction = (value) => {
    return {
        type: SET_ADMIN_VERIFY,
        payload: value,
    };
};

export const setAdminLoaderAction = (value) => {
    return {
        type: SET_ADMIN_LOADER,
        payload: value,
    };
};

export const adminLoginAction = (value) => {
    return {
        type: ADMIN_LOGIN,
        payload: value,
    };
};

export const makeOutboundCallAction = (value) => {
    return {
        type: MAKE_OUTBOUND_CALL,
        payload: value,
    };
};

export const setErrorMessage = (value) => {
    return {
        type: SET_ERROR_MESSAGE,
        payload: value,
    };
};

export const getListAssistants = (value) => {
    return {
        type: GET_ASSISTANTS_ID,
        payload: value,
    };
};

export const getListNumber = (value) => {
    return {
        type: GET_NUMBER_ID,
        payload: value,
    };
};

export const getListDocument = (value) => {
    return {
        type: DOCUMENT_ID,
        payload: value,
    };
};

export const setListAssistants = (value) => {
    return {
        type: SET_ASSISTANTS_ID,
        payload: value,
    };
};

export const setListNumber = (value) => {
    return {
        type: SET_NUMBER_ID,
        payload: value,
    };
};

export const setListDocument = (value) => {
    return {
        type: SET_DOCUMENT_ID,
        payload: value,
    };
};

export const addProjectAction = (value) => {
    return {
        type: ADD_PROJECT,
        payload: value,
    };
};

export const makeOutboundCallCSVAction = (value) => {
    return {
        type: MAKE_OUTBOUND_CALL_CSV,
        payload: value,
    };
};

export const downloadDataAction = (value) => {
    return {
        type: DOWNLOAD_DATA,
        payload: value,
    };
};

export const verifyAdminAction = (value) => {
    return {
        type: VERIFY_ADMIN_ACTION,
        payload: value,
    };
};

export const setProjectData = (value) => {
    return {
        type: SET_PROJECT_DATA,
        payload: value,
    };
};

export const getProjectDataAction = (value) => {
    return {
        type: GET_PROJECT_DATA,
        payload: value,
    };
};

export const logoutAdminAction = (value) => {
    return {
        type: LOGOUT_ADMIN,
        payload: value,
    };
};

export const isAdminLoginAction = (value) => {
    return {
        type: IS_ADMIN_LOGIN,
        payload: value,
    };
};

export const archiveProjectAction = (value) => {
    return {
        type: ARCHIVE_PROJECT,
        payload: value,
    };
};

export const setArchiveProjectAction = (value) => {
    return {
        type: SET_ARCHIVE_PROJECT,
        payload: value,
    };
};

export const createAssistantAction = (value) => {
    return {
        type: CREATE_ASSISTANT,
        payload: value,
    };
};

export const uploadDocumentPdfAction = (value) => {
    return {
        type: UPLOAD_DOCUMENT,
        payload: value,
    };
};

export const dropDocumentAction = (value) => {
    return {
        type: DROP_DOCUMENT,
        payload: value,
    };
};

export const uploadDocumentLoader = (value) => {
    return {
        type: UPLOAD_DOCUMENT_LOADER,
        payload: value,
    };
};

export const deleteDocument = (value) => {
    return {
        type: DELETE_DOCUMENT,
        payload: value,
    };
};
