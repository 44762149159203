import WavingHandIcon from "@mui/icons-material/WavingHand";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import {
  useMessages,
  useSendMessage,
  useStreamMessages,
} from "@xmtp/react-sdk";
import { isSameDay } from "date-fns";
import React, { useCallback, useEffect, useRef, useState } from "react";
import "../../index.css";
import { DateDivider } from "../DateDivider";

export default function ChatMessageList(props) {
  const renderedDatesRef = useRef([]);
  const { messages, isLoading, isLoaded } = useMessages(props.selectConvertion);
  // debugger;
  const { sendMessage } = useSendMessage();

  const [streamedMessages, setStreamedMessages] = useState([]);

  const scrollRef = useRef(null);

  const onMessage = useCallback(
    (message) => {
      setStreamedMessages((prev) => [...prev, message]);
    },
    [streamedMessages]
  );

  useStreamMessages(props.selectConvertion, { onMessage });

  useEffect(() => {
    setStreamedMessages([]);
  }, [props.selectConvertion]);

  useEffect(() => {
    if (scrollRef.current && messages && !isLoading) {
      scrollRef.current.scrollIntoView({ behaviour: "smooth" });
    }
  }, [messages, isLoading]);

  const getContentTypeFromFileName = (filename) => {
    // debugger;
    const suffix = filename.split?.(".")?.pop()?.toLowerCase();
    return suffix;
  };
  const checkDisplayDate = (msg, index) => {
    if (renderedDatesRef.current.length === 0) {
      renderedDatesRef.current.push(msg.sentAt);
    }
    const lastRenderedDate = renderedDatesRef.current.at(-1);
    const isFirstMessage = index === 0;
    const isSameDate = isSameDay(lastRenderedDate, msg.sentAt);
    const shouldDisplayDate = isFirstMessage || !isSameDate;

    if (shouldDisplayDate) {
      renderedDatesRef.current.push(msg.sentAt);
    }
    return shouldDisplayDate;
  };
  return (
    <div className="MessageContainer">
      {!isLoaded ? (
        <div className="messageLoader">Loading messages...</div>
      ) : messages && messages.length > 0 ? (
        messages.map((e, i) => (
          <>
            {console.log(e.content)}
            {checkDisplayDate(e, i) && (
              <DateDivider date={renderedDatesRef.current.at(-1)} />
            )}
            <div className="msgBox">
              <div
                style={{
                  display: "inline-block",
                  marginLeft:
                    props.client.address == e.senderAddress ? "auto" : "5px",
                  marginRight:
                    props.client.address == e.senderAddress ? "5px" : "auto",
                  padding: "0px",
                }}
              >
                <ListItem className="MessageItem" style={{ padding: "0px" }}>
                  {typeof e.content == "string" ? (
                    <ListItemText
                      id="Message"
                      style={{
                        marginTop: i == 0 ? "10px" : "10px",
                        padding: "5px 10px",
                        backgroundColor:
                          props.client.address == e.senderAddress
                            ? "#e95c43"
                            : "#f7ecef",
                        borderRadius: "5px",
                      }}
                      primaryTypographyProps={{
                        style: {
                          color:
                            props.client.address == e.senderAddress
                              ? "#fff"
                              : "#000",
                          textAlign: "justify",
                          whiteSpace: "pre-wrap",
                          wordWrap: "break-word",
                          paddingRight: "10px",
                          marginBottom: "2px",
                        },
                      }}
                      secondaryTypographyProps={{
                        style: {
                          fontSize: "10px",
                          color:
                            props.client.address == e.senderAddress
                              ? "#fff"
                              : "#000",
                          opacity: 0.5,
                          display: "flex",
                          justifyContent: "flex-end",
                        },
                      }}
                      primary={e.content}
                      secondary={`${new Date(e.sentAt).getHours()}:${String(
                        new Date(e.sentAt).getMinutes()
                      ).padStart(2, "0")}`}
                    />
                  ) : (
                    <div
                      style={{
                        marginTop: i == 0 ? "90px" : "10px",
                        position: "relative",
                      }}
                    >
                      {getContentTypeFromFileName(e.content.filename) ==
                        "mp4" ||
                      getContentTypeFromFileName(e.content.filename) ==
                        "mov" ? (
                        <video
                          width="320"
                          height="240"
                          controls
                          className="messageImage"
                        >
                          <source
                            src={e.content?.url ?? ""}
                            type={`video/${getContentTypeFromFileName(
                              e.content.filename
                            )}`}
                          />
                        </video>
                      ) : getContentTypeFromFileName(e.content.filename) ==
                          "mp3" ||
                        getContentTypeFromFileName(e.content.filename) ==
                          "mpeg" ||
                        getContentTypeFromFileName(e.content.filename) ==
                          "x-wav" ||
                        getContentTypeFromFileName(e.content.filename) ==
                          "wav" ? (
                        <audio controls src={e.content?.url ?? ""}></audio>
                      ) : getContentTypeFromFileName(e.content.filename) ==
                        "pdf" ? (
                        <object
                          data={e.content?.url ?? ""}
                          type="application/pdf"
                          className="messagepdf"
                        ></object>
                      ) : (
                        <img
                          src={e.content?.url ?? ""}
                          className="messageImage"
                        />
                      )}

                      <div className="imgTime">{`${new Date(
                        e.sentAt
                      ).getHours()}:${String(
                        new Date(e.sentAt).getMinutes()
                      ).padStart(2, "0")}`}</div>
                    </div>
                  )}
                </ListItem>
              </div>
            </div>
          </>
        ))
      ) : (
        <div className="sayhiContainer">
          <div className="startConversationText">
            This is the beginning of your conversation with{" "}
            {props.selectConvertion.peerAddress.substring(0, 6) +
              "..." +
              props.selectConvertion.peerAddress.substring(
                props.selectConvertion.peerAddress.length - 4
              )}
          </div>
          <div
            className="sayText"
            onClick={() => {
              void sendMessage(props.selectConvertion, "👋");
            }}
          >
            <WavingHandIcon className="wavinghand" />
            <div>Say hi</div>
          </div>
        </div>
      )}
      <li ref={scrollRef} style={{ visibility: "hidden" }} />
    </div>
  );
}
